import * as yup from 'yup'
import { Ordering } from '~utils/enums'

export const SECTION_HEADING_TEXT = 'Total Balance'

export const searchParamsSchema = yup.object({
  pageSize: yup.mixed().oneOf(['10', '20', '50', '100']),
  sortBy: yup.mixed().oneOf(['symbol', 'amount', 'total']),
  sortOrder: yup.mixed().oneOf(Object.values(Ordering)),
  page: yup
    .string()
    .matches(/^\d+$/)
    .test((value: any) => (value ? parseInt(value, 10) >= 1 : false)),
})

interface InitialSettings {
  [index: string]: string
}

export const INITIAL_SETTINGS: InitialSettings = {
  pageSize: '10',
  page: '1',
  sortBy: 'total',
  sortOrder: Ordering.DESC,
}

export const PAGE_SIZE_FILTER_TABS = [
  { key: '10', text: '10' },
  { key: '20', text: '20' },
  { key: '50', text: '50' },
  { key: '100', text: '100' },
]

export const TABLE_HEADER = [
  { sortKey: 'symbol', text: 'Token' },
  { sortKey: 'amount', text: 'Amount' },
  { sortKey: 'total', text: 'Total ($)' },
]
