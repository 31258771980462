import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './SVGDefs.module.scss'

const SVGDefs = (props) => {
  const { className, ...rest } = props

  return (
    <svg
      {...rest}
      className={classNames(style.svgDefs, className)}
      aria-hidden="true"
      focusable="false"
      tabIndex={-1}
    >
      <svg id="icon-logo" fill="none" viewBox="0 0 40 40">
        <rect width="40" height="40" rx="8" fill="url(#icon-logo-a)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7 11.37c-.56.08-1.9.03-2.67-.82-.78-.85-2.12-.82-2.68-.7 2.15-.95 7.4-1.9 11.2 1.88 1.5 1.45 4.12 5.7 2.56 11.07-.8 1.54-1.34 4.79 3.05 5.54-1.78.9-4.9 1.66-8.3.9-6.75 2.92-12.66.17-15.46-4.45-2.27-3.74-1.3-7.07-.36-8.2l.33-.03-.33.03c.65-.9 2.54-2.2 5.36-2.03 2.07.11 3.53.82 6.58 3.41 3.04 2.6 7.55 0 7.43-3.3-.12-3.3-4.02-4.7-3.17-2.12.61.83 1.36 2.71-.49 3.66 0-.63-.24-1.98-1.22-2.36-1.21-.47-2.55-1.3-1.82-2.48Z"
          fill="url(#icon-logo-b)"
        />
        <defs>
          <radialGradient
            id="icon-logo-b"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(11.16825 -6.25422 10.56516 18.86638 11.61 27.32)"
          >
            <stop stopColor="#434649" />
            <stop offset="1" />
          </radialGradient>
          <linearGradient
            id="icon-logo-a"
            x1="0"
            y1="0"
            x2="41.87"
            y2="2.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4FDA7" />
            <stop offset="1" stopColor="#A6FCF0" />
          </linearGradient>
        </defs>
      </svg>
      <svg id="icon-solana" fill="none" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="12" fill="#1F2021" />
        <path
          d="m18.271 15.302-2.202 2.361a.51.51 0 0 1-.373.163H5.256a.256.256 0 0 1-.188-.43l2.2-2.362a.51.51 0 0 1 .373-.162h10.44a.254.254 0 0 1 .19.43Zm-2.202-4.757a.517.517 0 0 0-.373-.163H5.256a.256.256 0 0 0-.188.43l2.2 2.363a.516.516 0 0 0 .373.163h10.44a.256.256 0 0 0 .186-.43l-2.198-2.363ZM5.256 8.85h10.44a.51.51 0 0 0 .373-.163l2.202-2.362a.255.255 0 0 0-.19-.43H7.64a.51.51 0 0 0-.373.163l-2.2 2.362a.256.256 0 0 0 .188.43Z"
          fill="url(#a)"
        />
        <defs>
          <linearGradient
            id="a"
            x1="6.126"
            y1="18.11"
            x2="17.073"
            y2="5.769"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".08" stopColor="#9945FF" />
            <stop offset=".3" stopColor="#8752F3" />
            <stop offset=".5" stopColor="#5497D5" />
            <stop offset=".6" stopColor="#43B4CA" />
            <stop offset=".72" stopColor="#28E0B9" />
            <stop offset=".97" stopColor="#19FB9B" />
          </linearGradient>
        </defs>
      </svg>
    </svg>
  )
}

SVGDefs.defaultProps = {
  className: '',
}

SVGDefs.propTypes = {
  className: PropTypes.string,
}

export default SVGDefs
