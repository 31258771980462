import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Modal, Table } from 'react-bootstrap'
import { orderBy, isNumber } from 'lodash'

import { handleImgError } from '~utils/handleImgError'
import TableHead, { Heading } from '~components/TableHead'
import InputSearch from '~components/InputSearch/InputSearch'
import Icon from '~components/Icon'

import { Ordering } from '~utils/enums'
import { normalizeString } from './utils'

import * as style from './FarmPairModal.module.scss'

interface FarmPairModalProps {
  show: boolean
  title: string
  imgSrc: string
  tableHeader: Heading[]
  data: (string | number | number[] | string[])[][]
  onHide: () => void
  className?: string
}

const FarmPairModal: React.FC<FarmPairModalProps> = (props) => {
  const { show, title, imgSrc, data, tableHeader, onHide, className, ...rest } =
    props

  const modalRef = useRef<HTMLDivElement>(null)
  const [modalHeight, setModalHeight] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortParams, setSortParams] = useState({ sortBy: '', sortOrder: '' })

  useEffect(() => {
    setSearchQuery('')
    setSortParams({ sortBy: '', sortOrder: '' })

    if (show && modalRef.current) {
      setModalHeight(modalRef.current.getBoundingClientRect().height)
    }
  }, [show])

  const filteredData = data.filter((item) =>
    normalizeString(item[0]).includes(normalizeString(searchQuery))
  )

  const sortedFarmPairs = ['total', 'amount'].includes(sortParams.sortBy)
    ? orderBy(
        filteredData,
        [+(sortParams.sortBy === 'total') + 1],
        sortParams.sortOrder as Ordering
      )
    : filteredData

  return (
    <Modal
      show={show}
      {...rest}
      centered
      className={classNames('modal-farm', className)}
      onHide={onHide}
    >
      <div className={style.modalWrapper}>
        <Modal.Body
          className={style.modalContent}
          ref={modalRef}
          style={{
            height: searchQuery && modalHeight ? `${modalHeight}px` : '100%',
          }}
        >
          <div className={style.modalTitle}>
            <img
              src={imgSrc}
              alt={title}
              className={style.modalTitleImg}
              onError={handleImgError}
            />
            <h1>{title}</h1>
          </div>
          <InputSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div className={style.tableWrapper}>
            <Table className={style.table}>
              <TableHead
                tableHead={tableHeader}
                sortParams={sortParams}
                setSortParams={setSortParams}
              />
              <tbody>
                {sortedFarmPairs.map((row) => (
                  <tr key={row.toString()}>
                    {row.map(
                      (cell) =>
                        cell !== null &&
                        (!Array.isArray(cell) ? (
                          <td key={cell.toString()}>
                            {isNumber(cell) ? cell.toFixed(4) : cell}
                          </td>
                        ) : (
                          <td key={cell.toString()}>
                            <div className={style.cell}>
                              {cell.map((value) => (
                                <span key={value}>
                                  {isNumber(value) ? value.toFixed(4) : value}
                                </span>
                              ))}
                            </div>
                          </td>
                        ))
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <button type="button" onClick={onHide} className={style.closeButton}>
          <Icon name="icon-close" size={24} />
        </button>
      </div>
    </Modal>
  )
}

export default FarmPairModal
