import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

export const TEXT_FRESH_RESULTS = 'just now'
export const TEXT_UPDATING = 'updating'

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: (s: any) => (s === TEXT_FRESH_RESULTS ? s : `${s} ago`),
    s: TEXT_FRESH_RESULTS,
    m: TEXT_FRESH_RESULTS,
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

export const toRelativeTime = (timestamp: number | undefined) => {
  return dayjs().to(dayjs(timestamp))
}
