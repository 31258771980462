import React, { createRef, useEffect } from 'react'
import classNames from 'classnames'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import Treemap from 'highcharts/modules/treemap'
import { Placeholder } from 'react-bootstrap'

import ButtonUpdate from '~components/ButtonUpdate'

import { TreemapDataItem } from './types'
import { ExtraChartOptions, getChartOptions } from './constants'

import * as style from './TreemapStylized.module.scss'

Treemap(Highcharts)

interface TreemapStylizedProps {
  className?: string
  extraChartOptions?: ExtraChartOptions
  onRefetchClick?: Function
  data?: TreemapDataItem[]
  isRefetching?: boolean
  dataUpdatedAt?: number
  chartComponentRef?: any
}

const TreemapStylized: React.FC<TreemapStylizedProps> = (props) => {
  const {
    className,
    data,
    extraChartOptions,
    onRefetchClick,
    isRefetching = false,
    dataUpdatedAt,
    chartComponentRef = createRef<any>(),
    ...rest
  } = props

  useEffect(() => {
    const firstSeries = chartComponentRef.current?.chart?.series?.[0]

    firstSeries?.setRootNode?.(firstSeries?.rootNode)
  }, [dataUpdatedAt, chartComponentRef])

  const options = getChartOptions(data, extraChartOptions)

  return (
    <div className={style.chartOuterWrapper}>
      <ButtonUpdate
        className={style.btnUpdate}
        onClick={onRefetchClick}
        isRefetching={isRefetching}
        dataUpdatedAt={dataUpdatedAt}
      />
      {data ? (
        <HighchartsReact
          {...rest}
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
          containerProps={{
            className: classNames(style.chartContainer, className),
          }}
        />
      ) : (
        <Placeholder bg="chart" />
      )}
    </div>
  )
}

export default TreemapStylized
