import React from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'
import RouteURL from '~routes'

import SectionWalletEvaluation from './components/SectionWalletEvaluation'
import SectionCurrenciesHeld from './components/SectionCurrenciesHeld'
import SectionHotNFTs from './components/SectionHotNFTs'
import SectionNftsHeldTreemap from './components/SectionNftsHeldTreemap'
import SectionHotNftsTreemap from './components/SectionHotNftsTreemap'

const Summary = () => {
  return (
    <Routes>
      <Route
        path={RouteURL.Summary.WALLET_EVALUATION}
        element={<SectionWalletEvaluation />}
      />
      <Route
        path={RouteURL.Summary.CURRENCIES_HELD}
        element={<SectionCurrenciesHeld />}
      />
      <Route path={RouteURL.Summary.HOT_NFTS} element={<SectionHotNFTs />} />
      <Route
        path={RouteURL.Summary.NFTS_HELD_TREEMAP}
        element={<SectionNftsHeldTreemap />}
      />
      <Route
        path={RouteURL.Summary.HOT_NFTS_TREEMAP}
        element={<SectionHotNftsTreemap />}
      />
      <Route
        path="*"
        element={<Navigate to={RouteURL.Summary.WALLET_EVALUATION} replace />}
      />
    </Routes>
  )
}

export default Summary
