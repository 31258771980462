import { round } from 'lodash'

export const SECTION_HEADING_TEXT = 'Hot NFTs Treemap'

export const getLeadText = (
  nftCount: number | undefined,
  collectionsCount: number | undefined,
  totalValue: number | undefined,
  period: string
) =>
  `${nftCount || 0} NFTs purchased from ${
    collectionsCount || 0
  } collections for ${round(totalValue || 0, 2)} SOL in the last ${period}.`

export const PERIOD_FILTER_TABS = [
  {
    key: '24h-purch',
    text: '24H Purch.',
    filedName: 'buys1d',
    countFieldName: 'buys1d',
    valueFiledName: 'buys_volume1d',
    textPeriod: '24 hours',
    isActive: true,
  },
  {
    key: '1w-purch',
    text: '1W Purch.',
    filedName: 'buys7d',
    countFieldName: 'buys7d',
    valueFiledName: 'buys_volume7d',
    textPeriod: '7 days',
  },
  {
    key: '24h-vol',
    text: '24H Vol.',
    filedName: 'buys_volume1d',
    countFieldName: 'buys1d',
    valueFiledName: 'buys_volume1d',
    textPeriod: '24 hours',
  },
  {
    key: '1w-vol',
    text: '1W Vol.',
    filedName: 'buys_volume7d',
    countFieldName: 'buys7d',
    valueFiledName: 'buys_volume7d',
    textPeriod: '7 days',
  },
]

export const DEFAULT_FILTER_KEY = PERIOD_FILTER_TABS[0].key
