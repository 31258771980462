import ImgixClient from '@imgix/js-core'

const imgixClient = process.env.REACT_APP_IMGIX_DOMAIN
  ? new ImgixClient({
      domain: process.env.REACT_APP_IMGIX_DOMAIN,
      secureURLToken: process.env.REACT_APP_IMGIX_SECURE_URL_TOKEN,
    })
  : undefined

export default imgixClient
