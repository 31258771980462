const updateActiveItem = (
  prevState: any[],
  newActiveKey: string | number,
  fieldName = 'key'
) => {
  return prevState.map((tabItem) =>
    tabItem[fieldName] === newActiveKey
      ? { ...tabItem, isActive: true }
      : { ...tabItem, isActive: false }
  )
}

export default updateActiveItem
