export const MOCK_WALLET_EVALUATION = [
  {
    timestamp: 1651622400000,
    value: 9.59,
  },
  {
    timestamp: 1651608000000,
    value: 5.77,
  },
  {
    timestamp: 1651593600000,
    value: 44.58,
  },
  {
    timestamp: 1651579200000,
    value: 30.51,
  },
  {
    timestamp: 1651564800000,
    value: 9.47,
  },
  {
    timestamp: 1651550400000,
    value: 8.53,
  },
  {
    timestamp: 1651536000000,
    value: 15.41,
  },
  {
    timestamp: 1651521600000,
    value: 35.21,
  },
  {
    timestamp: 1651507200000,
    value: 13.85,
  },
  {
    timestamp: 1651492800000,
    value: 7.79,
  },
  {
    timestamp: 1651478400000,
    value: 13.7,
  },
  {
    timestamp: 1651464000000,
    value: 27.22,
  },
  {
    timestamp: 1651449600000,
    value: 29.37,
  },
  {
    timestamp: 1651435200000,
    value: 4.6,
  },
  {
    timestamp: 1651420800000,
    value: 26.89,
  },
  {
    timestamp: 1651406400000,
    value: 41.5,
  },
  {
    timestamp: 1651392000000,
    value: 49.88,
  },
  {
    timestamp: 1651377600000,
    value: 30.64,
  },
  {
    timestamp: 1651363200000,
    value: 0.01,
  },
  {
    timestamp: 1651348800000,
    value: 42.72,
  },
  {
    timestamp: 1651334400000,
    value: 37.91,
  },
  {
    timestamp: 1651320000000,
    value: 22.32,
  },
  {
    timestamp: 1651305600000,
    value: 15.24,
  },
  {
    timestamp: 1651291200000,
    value: 4.39,
  },
  {
    timestamp: 1651276800000,
    value: 8.86,
  },
  {
    timestamp: 1651262400000,
    value: 19.74,
  },
  {
    timestamp: 1651248000000,
    value: 44.79,
  },
  {
    timestamp: 1651233600000,
    value: 40.38,
  },
  {
    timestamp: 1651219200000,
    value: 24.16,
  },
  {
    timestamp: 1651204800000,
    value: 18.26,
  },
  {
    timestamp: 1651190400000,
    value: 39.97,
  },
  {
    timestamp: 1651176000000,
    value: 44.19,
  },
  {
    timestamp: 1651161600000,
    value: 48.95,
  },
  {
    timestamp: 1651147200000,
    value: 9.24,
  },
  {
    timestamp: 1651132800000,
    value: 29.87,
  },
  {
    timestamp: 1651118400000,
    value: 20.27,
  },
  {
    timestamp: 1651104000000,
    value: 31.18,
  },
  {
    timestamp: 1651089600000,
    value: 7.71,
  },
  {
    timestamp: 1651075200000,
    value: 8.31,
  },
  {
    timestamp: 1651060800000,
    value: 14.04,
  },
  {
    timestamp: 1651046400000,
    value: 41.89,
  },
  {
    timestamp: 1651032000000,
    value: 44.49,
  },
]
