import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'

import SectionHeader from '~components/SectionHeader'
import TreemapStylized from '~components/TreemapStylized'
import useMatchMedia from '~hooks/useMatchMedia'
import { getNFTsHeld } from '~api'

import { formatData } from './utils'
import { getLeadText, TEXT_PAGE_HEADING } from './constants'

import * as style from './SectionNftsHeldTreemap.module.scss'

interface SectionNftsHeldTreemapProps {
  className?: string
}

const SectionNftsHeldTreemap: React.FC<SectionNftsHeldTreemapProps> = (
  props
) => {
  const { className, ...rest } = props

  const {
    data: queryResponse,
    dataUpdatedAt,
    refetch,
    isRefetching,
    isLoading,
  } = useQuery('', () => getNFTsHeld(), { refetchInterval: false })

  const isMobile = useMatchMedia('(max-width: 639.98px)')

  const { groupedData, nftCount, collectionsCount } = useMemo(
    () => formatData(queryResponse, isMobile),
    [queryResponse, isMobile]
  )

  return (
    <div
      {...rest}
      className={classNames(style.sectionNftsHeldTreemap, className)}
    >
      <SectionHeader
        headingText={TEXT_PAGE_HEADING}
        leadText={getLeadText(nftCount, collectionsCount)}
        isLoading={isLoading}
      />
      <TreemapStylized
        data={groupedData}
        dataUpdatedAt={dataUpdatedAt}
        onRefetchClick={refetch}
        isRefetching={isRefetching || isLoading}
      />
    </div>
  )
}

export default SectionNftsHeldTreemap
