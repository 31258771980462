import React, { createRef, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import SectionHeader from '~components/SectionHeader'
import TabsFilter, { TabItem } from '~components/TabsFilter'
import OffcanvasFilters from '~components/OffcanvasFilters'
import TreemapStylized from '~components/TreemapStylized'
import { HotNft } from '~api/types'
import { getHotNFTs, QueryKey } from '~api'
import useMatchMedia from '~hooks/useMatchMedia'

import {
  SECTION_HEADING_TEXT,
  PERIOD_FILTER_TABS,
  DEFAULT_FILTER_KEY,
  getLeadText,
} from './constants'
import { processDataByFilter } from './utils'

import * as style from './SectionHotNftsTreemap.module.scss'

interface SectionHotNftsTreemapProps {
  className?: string
}

const SectionHotNftsTreemap: React.FC<SectionHotNftsTreemapProps> = (props) => {
  const { className, ...rest } = props

  const {
    data: queryData,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery<HotNft[]>(QueryKey.Summary.HOT_NFTS, () => getHotNFTs(), {
    refetchInterval: false,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeFilterKey, setActiveFilterKey] = useState(
    PERIOD_FILTER_TABS[0].key
  )

  useEffect(() => {
    const filterKey = searchParams.get('filter') || DEFAULT_FILTER_KEY

    setActiveFilterKey(filterKey)
  }, [])

  const activeFilterObj: TabItem =
    PERIOD_FILTER_TABS.find(({ key }) => key === activeFilterKey) ||
    PERIOD_FILTER_TABS[0]

  const chartComponentRef = createRef<any>()

  const handleSetActiveTabKey = (eventKey: string) => {
    setSearchParams({ filter: eventKey })
    setActiveFilterKey(eventKey)

    chartComponentRef.current?.chart?.series?.[0].setRootNode('')
  }

  const isMobile = useMatchMedia('(max-width: 639.98px)')

  const { nftCount, totalValue, collectionsCount, groupedData } = useMemo(
    () => processDataByFilter(queryData, isMobile, activeFilterObj),
    [queryData, isMobile, activeFilterObj]
  )

  const offcanvasFilters = [
    {
      name: 'Purchases',
      tabs: PERIOD_FILTER_TABS.slice(0, 2),
      activeTabKey: activeFilterKey,
      setActiveTabKey: handleSetActiveTabKey,
    },
    {
      name: 'Volume',
      tabs: PERIOD_FILTER_TABS.slice(2),
      activeTabKey: activeFilterKey,
      setActiveTabKey: handleSetActiveTabKey,
    },
  ]

  return (
    <div
      {...rest}
      className={classNames(style.sectionHotNftsTreemap, className)}
    >
      <SectionHeader
        headingText={SECTION_HEADING_TEXT}
        leadText={getLeadText(
          nftCount,
          collectionsCount,
          totalValue,
          activeFilterObj.textPeriod
        )}
        filters={
          <TabsFilter
            tabs={PERIOD_FILTER_TABS}
            setActiveTabKey={handleSetActiveTabKey}
            activeTabKey={activeFilterKey}
          />
        }
        offcanvasFilters={
          <OffcanvasFilters offcanvasFilters={offcanvasFilters} />
        }
        isLoading={isLoading}
      />
      <TreemapStylized
        data={groupedData}
        dataUpdatedAt={dataUpdatedAt}
        isRefetching={isRefetching || isLoading}
        onRefetchClick={refetch}
        chartComponentRef={chartComponentRef}
      />
    </div>
  )
}

export default SectionHotNftsTreemap
