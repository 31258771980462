interface DataItem {
  name: string
  value: number
  [otherProps: string]: any
}

const sortAndSlice = (
  data: DataItem[],
  { fieldName, count }: { fieldName: keyof DataItem; count: number } = {
    fieldName: 'value',
    count: 125,
  }
) => data.sort((a, b) => -(a[fieldName] - b[fieldName])).slice(0, count)

export default sortAndSlice
