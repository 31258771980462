import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { chunk, orderBy, sumBy } from 'lodash'
import { useSearchParams } from 'react-router-dom'

import SectionHeader from '~components/SectionHeader'
import OffcanvasFilters from '~components/OffcanvasFilters'
import DropdownFilter from '~components/DropdownFilter'
import Paginator from '~components/Paginator'
import { Token } from '~api/types'
import { QueryKey } from '~api'
import { TOKEN_BALANCE_DATA_MOCK } from '~api/mocks'
import { Ordering } from '~utils/enums'
import validateSearchParams from '~utils/validateSearchParams'

import {
  INITIAL_SETTINGS,
  PAGE_SIZE_FILTER_TABS,
  searchParamsSchema,
  SECTION_HEADING_TEXT,
  TABLE_HEADER,
} from './constants'
import TokenBalanceTable from './components/TokenBalanceTable'

interface SectionTokenBalanceProps {
  className?: string
}

const SectionTokenBalance: React.FC<SectionTokenBalanceProps> = (props) => {
  const { className, ...rest } = props

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_SETTINGS)

  const [pageSettings, setPageSettings] = useState(
    validateSearchParams(searchParams, searchParamsSchema, INITIAL_SETTINGS)
  )

  useEffect(() => {
    setSearchParams({ ...pageSettings }, { replace: true })
  }, [pageSettings])

  const updateSettings = (newSettings: object) => {
    setPageSettings((prevState) => ({ ...prevState, ...newSettings }))
  }

  const handleSort = (sortParams: { sortBy: string; sortOrder: string }) =>
    updateSettings(sortParams)

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)

    updateSettings({ page: (page + 1).toString() })
  }

  const handlePageSizeChange = (pageSize: number) => {
    updateSettings({ pageSize: pageSize.toString() })
  }

  const offcanvasFilters = [
    {
      name: 'Rows per page',
      tabs: PAGE_SIZE_FILTER_TABS,
      setActiveTabKey: handlePageSizeChange,
      activeTabKey: pageSettings.pageSize,
    },
  ]

  const { data, isLoading } = useQuery<Token[]>(
    [QueryKey.Summary.TOKENS_HELD],
    () => TOKEN_BALANCE_DATA_MOCK,
    {
      refetchInterval: false,
    }
  )

  const preparedData: Token[] | undefined = useMemo(
    () =>
      data?.map((token) => ({
        ...token,
        total: token.amount * token.price,
      })),
    [data]
  )

  const totalAmount = useMemo(
    () => sumBy(preparedData, (item) => item.total),
    [preparedData]
  )

  const sortedData = useMemo(
    () =>
      orderBy(
        preparedData,
        [pageSettings.sortBy],
        [pageSettings.sortOrder as Ordering]
      ),
    [preparedData, pageSettings.sortBy, pageSettings.sortOrder]
  )

  const paginatedData = useMemo(
    () => chunk(sortedData, +pageSettings.pageSize),
    [sortedData, pageSettings.pageSize]
  )

  const pageInt = +pageSettings.page - 1

  useEffect(() => {
    if (paginatedData?.length && pageInt >= paginatedData.length)
      updateSettings({ page: paginatedData.length })
  }, [pageInt, paginatedData])

  return (
    <main {...rest} className={className}>
      <SectionHeader
        headingText={SECTION_HEADING_TEXT}
        leadText={`$${totalAmount.toLocaleString()}`}
        filters={
          <DropdownFilter
            dropdownItems={PAGE_SIZE_FILTER_TABS}
            activeItemKey={pageSettings.pageSize}
            setActiveItemKey={handlePageSizeChange}
            label="Per page"
          />
        }
        offcanvasFilters={
          <OffcanvasFilters offcanvasFilters={offcanvasFilters} />
        }
        isLoading={isLoading}
      />
      <TokenBalanceTable
        tableHeader={TABLE_HEADER}
        isLoading={isLoading}
        onSort={handleSort}
        pageInt={pageInt}
        pageSettings={pageSettings}
        paginatedData={paginatedData}
      />
      {!isLoading && (
        <Paginator
          pageCount={paginatedData.length}
          selectedPage={pageInt}
          setSelectedPage={handlePageChange}
        />
      )}
    </main>
  )
}

export default SectionTokenBalance
