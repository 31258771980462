import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { round, chunk, parseInt } from 'lodash'

import SectionHeader from '~components/SectionHeader'
import DropdownFilter from '~components/DropdownFilter'
import TabsFilter from '~components/TabsFilter'
import OffcanvasFilters from '~components/OffcanvasFilters'
import Paginator from '~components/Paginator'

import validateSearchParams from '~utils/validateSearchParams'
import { getHotNFTs, QueryKey } from '~api'
import { HotNft } from '~api/types'
import imgixClient from '~utils/imgixClient'

import NftCard from './components/NftCard'
import { getSubtitle } from './utils'
import {
  PAGE_SIZE_FILTER_TABS,
  PERIOD_FILTER_TABS,
  SECTION_HEADING_TEXT,
  HOT_NFTS_GRID_PAGE_SIZE,
  searchParamsSchema,
  INITIAL_SETTINGS,
} from './constants'
import * as style from './SectionHotNFTs.module.scss'

interface SectionHotNFTsProps {
  className?: string
}

const SectionHotNFTs: React.FC<SectionHotNFTsProps> = (props) => {
  const { className, ...rest } = props

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_SETTINGS)
  const [pageSettings, setPageSettings] = useState(
    validateSearchParams(searchParams, searchParamsSchema, INITIAL_SETTINGS)
  )

  const handleChangePage = (pageRaw: string) => {
    window.scrollTo(0, 0)

    const page = (+pageRaw + 1).toString()
    setPageSettings((prevState) => ({ ...prevState, page }))
  }

  const handleChangeSettings = (field: string, value: string) => {
    setPageSettings((prevState) => ({
      ...prevState,
      [field]: value,
      page: INITIAL_SETTINGS.page,
    }))
  }

  useEffect(() => {
    setSearchParams({ ...pageSettings }, { replace: true })
  }, [pageSettings])

  const { data, isLoading } = useQuery<HotNft[]>(
    QueryKey.Summary.HOT_NFTS,
    () => getHotNFTs()
  )

  const isDayFilter = pageSettings.periodFilter === '24h'

  const offcanvasFilters = [
    {
      name: 'Period',
      tabs: PERIOD_FILTER_TABS,
      setActiveTabKey: (key: string) =>
        handleChangeSettings('periodFilter', key),
      activeTabKey: pageSettings.periodFilter,
    },
    {
      name: 'Rows per page',
      tabs: PAGE_SIZE_FILTER_TABS,
      setActiveTabKey: (key: string) => handleChangeSettings('pageSize', key),
      activeTabKey: pageSettings.pageSize,
    },
  ]

  const paginatedData = useMemo(
    () =>
      chunk(
        data?.filter(({ buys1d, buys7d }) =>
          isDayFilter ? buys1d > 0 : buys7d > 0
        ),
        +pageSettings.pageSize
      ),
    [data, pageSettings.pageSize, isDayFilter]
  )

  const currentStats = useMemo(
    () =>
      data?.reduce(
        (acc, curr) => {
          acc.count1d += parseInt(curr.buys1d.toString(), 10)
          acc.count7d += parseInt(curr.buys7d.toString(), 10)
          acc.volume1d += parseFloat(curr.buys_volume1d.toString())
          acc.volume7d += parseFloat(curr.buys_volume7d.toString())
          return acc
        },
        { count1d: 0, count7d: 0, volume1d: 0, volume7d: 0 }
      ),
    [data]
  )

  const pageInt = +pageSettings.page - 1

  useEffect(() => {
    if (paginatedData?.length && pageInt >= paginatedData.length)
      setPageSettings((prevState) => ({
        ...prevState,
        page: paginatedData.length.toString(),
      }))
  }, [pageInt, paginatedData])

  const subtitle = getSubtitle(currentStats, data?.length || 0, isDayFilter)

  return (
    <main {...rest} className={className}>
      <SectionHeader
        headingText={SECTION_HEADING_TEXT}
        leadText={subtitle || ' '}
        isLoading={isLoading}
        filters={
          <>
            <DropdownFilter
              dropdownItems={PAGE_SIZE_FILTER_TABS}
              activeItemKey={pageSettings.pageSize}
              setActiveItemKey={(key: string) =>
                handleChangeSettings('pageSize', key)
              }
              label="Per page"
            />
            <TabsFilter
              tabs={PERIOD_FILTER_TABS}
              setActiveTabKey={(key: string) =>
                handleChangeSettings('periodFilter', key)
              }
              activeTabKey={pageSettings.periodFilter}
            />
          </>
        }
        offcanvasFilters={
          <OffcanvasFilters offcanvasFilters={offcanvasFilters} />
        }
      />
      <div className={style.cardsWrapper}>
        {isLoading
          ? Array.from({ length: HOT_NFTS_GRID_PAGE_SIZE }, (v, i) => i).map(
              (value) => <NftCard key={value} isLoading />
            )
          : paginatedData[+pageSettings.page - 1]?.map(
              ({
                buys1d,
                buys7d,
                buys_volume1d: buysVolume1d,
                buys_volume7d: buysVolume7d,
                nft,
              }) => {
                const buys = isDayFilter ? buys1d : buys7d
                const volume = isDayFilter ? buysVolume1d : buysVolume7d

                const {
                  update_authority: key,
                  collection,
                  image_uri: imgSrc,
                } = nft

                return buys > 0 ? (
                  <NftCard
                    key={key}
                    purchases={buys}
                    volume={round(volume, 2)}
                    collection={collection}
                    imgSrc={imgSrc}
                    isDayFilter={isDayFilter}
                    imgixClient={imgixClient}
                  />
                ) : null
              }
            )}
      </div>
      {!isLoading && paginatedData.length > 1 && (
        <Paginator
          pageCount={paginatedData.length}
          selectedPage={pageInt}
          setSelectedPage={handleChangePage}
        />
      )}
    </main>
  )
}

export default SectionHotNFTs
