import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'

import NavBar from '~components/NavBar'
import Copyright from '~components/Copyright'

import SVGDefs from './components/SVGDefs'
import * as style from './Layout.module.scss'

interface LayoutProps {
  children: any
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Container className={style.layout}>
        <NavBar />
        {children}
        <Copyright className={style.copyright} />
      </Container>
      <SVGDefs />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
