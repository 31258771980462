import { TreemapDataItem } from '~components/TreemapStylized/types'
import { groupDataByQuantiles } from '~components/TreemapStylized/utils'
import { sum } from 'd3'
import sortAndSlice from '~utils/sortAndSlice'

export const formatData = (queryResponse: any, isMobile = false) => {
  if (!queryResponse)
    return { groupedData: undefined, collectionsCount: 512, nftCount: 3072 }

  const formattedData: TreemapDataItem[] = queryResponse.holdings.map(
    (collection: any) => ({
      name: collection.nft.collection,
      value: parseFloat(collection.tokens_held),
    })
  )

  const collectionsCount = queryResponse.count
  const nftCount = sum(formattedData, ({ value }) => value)

  const groupedData = groupDataByQuantiles(
    isMobile ? sortAndSlice(formattedData) : formattedData,
    isMobile ? 6 : 16
  )

  return { groupedData, collectionsCount, nftCount }
}
