import Highcharts from 'highcharts'

export const SECTION_HEADING_TEXT = 'LP Tokens'

export const PERIOD_FILTER_TABS = [
  { key: '24h', text: '24H', isActive: true },
  { key: '1w', text: '1W' },
  { key: '1m', text: '1M' },
]

export const CHART_OPTIONS: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  colors: ['#C4FDA7'],
  chart: {
    type: 'areaspline',
    backgroundColor: '#111213',
    borderRadius: 16,
    style: {
      fontFamily: 'inherit',
      minHeight: '450px',
      maxHeight: '620px',
      maxWidth: '1096px',
    },
    spacing: [90, 48, 32, 0],
  },
  title: {
    text: '',
  },
  yAxis: {
    title: {
      text: 'ΔValue',
      align: 'high',
      rotation: 0,
      offset: 0,
      useHTML: true,
      style: {
        color: '#fff',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700',
      },
      x: 30,
      y: -40,
    },
    gridLineColor: '#1f2021',
    gridLineWidth: 1,
    labels: {
      style: {
        color: '#fff',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '500',
      },
    },
  },
  xAxis: {
    title: {
      text: 'Timestamp',
      offset: 52,
      style: {
        color: '#fff',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700',
      },
    },
    labels: {
      style: {
        color: '#fff',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '500',
      },
      format: '{value:%a %d}',
    },
    gridLineColor: '#1f2021',
    gridLineWidth: 1,
    tickLength: 0,
    lineWidth: 0,
    tickInterval: 24 * 3600 * 1000,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    // @ts-ignore
    headerFormat: '{point.key:%a %d}',
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      lineWidth: 3,
      color: {
        linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
        stops: [
          [0, '#C4FDA7'], // start
          [1, '#A6FCF0'], // end
        ],
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, 'rgba(177, 253, 214, 0.2)'], // start
          [1, 'rgba(167, 252, 240, 0)'], // end
        ],
      },
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: { maxWidth: 1090 },
        chartOptions: {
          chart: { spacing: [90, 24, 32, 0] },
        },
      },
      {
        condition: { maxWidth: 600 },
        chartOptions: {
          chart: {
            spacing: [60, 16, 16, -8],
            style: { minHeight: '320px' },
            scrollablePlotArea: { minWidth: 560, scrollPositionX: 1 },
          },
          xAxis: {
            title: {
              style: {
                color: '#fff',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: '700',
              },
            },
            labels: {
              style: {
                fontSize: '10px',
                lineHeight: '12px',
              },
            },
            scrollbar: {
              enabled: true,
            },
          },
          yAxis: {
            title: {
              style: {
                color: '#fff',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: '700',
              },
              x: 24,
              y: -24,
            },
            labels: {
              style: {
                fontSize: '10px',
                lineHeight: '12px',
              },
            },
          },
        },
      },
    ],
  },
}
