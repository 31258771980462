import RouteURL from '~routes'

export const FOOTER_LINKS = [
  {
    name: 'About',
    link: RouteURL.ABOUT,
  },
  {
    name: 'Litepaper',
    link: RouteURL.LITEPAPER,
  },
  {
    name: 'FAQ',
    link: RouteURL.FAQ,
  },
]

export const DEFAULT_IMG_URL =
  'https://res.cloudinary.com/the-minimal/image/upload/wise-whales/token-icons-old/default.png'

export const getImgUrl = (filename: string) =>
  `https://res.cloudinary.com/the-minimal/image/upload/wise-whales/token-icons-old/${filename}.png`
