import React, { useState } from 'react'
import classNames from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import SectionHeader from '~components/SectionHeader'
import TabsFilter from '~components/TabsFilter'
import OffcanvasFilters from '~components/OffcanvasFilters'
import { MOCK_WALLET_EVALUATION } from './mocks'
import {
  CHART_OPTIONS,
  PERIOD_FILTER_TABS,
  SECTION_HEADING_TEXT,
} from './constants'

import * as style from './SectionWalletEvaluation.module.scss'

interface SectionWalletEvaluationProps {
  className?: string
}

const SectionWalletEvaluation: React.FC<SectionWalletEvaluationProps> = (
  props
) => {
  const { className, ...rest } = props

  const seriesData = MOCK_WALLET_EVALUATION.map(({ value, timestamp }) => [
    timestamp,
    value,
  ])

  const seriesOptions: Highcharts.Options = {
    // @ts-ignore
    series: [{ data: seriesData }],
  }

  const options = Highcharts.merge(CHART_OPTIONS, seriesOptions)

  const [periodFilter, setPeriodFilter] = useState(PERIOD_FILTER_TABS)

  const offcanvasFilters = [
    { name: 'Period', tabs: periodFilter, setTabs: setPeriodFilter },
  ]

  return (
    <main
      {...rest}
      className={classNames(style.sectionWalletEvaluation, className)}
    >
      <SectionHeader
        headingText={SECTION_HEADING_TEXT}
        leadText={`The ${periodFilter.find(({ isActive }) => isActive)
          ?.text} Value Delta for Wise Whales`}
        filters={<TabsFilter tabs={periodFilter} setTabs={setPeriodFilter} />}
        offcanvasFilters={
          <OffcanvasFilters offcanvasFilters={offcanvasFilters} />
        }
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ className: style.chartContainer }}
      />
    </main>
  )
}

export default SectionWalletEvaluation
