import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import RouteURL from '~routes'

import logoSrc from './assets/logo.svg'
import * as style from './Header.module.scss'

interface HeaderProps {
  handleShowModal?: () => void
  isModalOpen: boolean
  className?: string
}

const Header: React.FC<HeaderProps> = (props) => {
  const { handleShowModal, isModalOpen, className, ...rest } = props

  return (
    <header {...rest} className={classNames(style.header, className)}>
      <Link to={RouteURL.DEFI}>
        <img
          src={logoSrc}
          alt="wise whales logo"
          width="134px"
          height="40px"
          className={classNames(style.logo, {
            [style.active]: isModalOpen,
          })}
        />
      </Link>
      <button
        {...rest}
        type="button"
        aria-expanded={isModalOpen}
        onClick={handleShowModal}
        className={classNames(style.hamb, className)}
      >
        <svg fill="none" viewBox="0 0 24 24" className={style.hambIcon}>
          <path
            className={classNames(style.hambLine, style.hambLineTop)}
            d="M4 8h16 c1 0 3 .4 3 2s-2 2-3 2 h-8"
          />
          <path
            className={classNames(style.hambLine, style.hambLineMiddle)}
            d="M4 12 h16"
          />
          <path
            className={classNames(style.hambLine, style.hambLineBottom)}
            d="M20 16 h-16 c-1 0-3 -.4-3 -2s2 -2 3 -2h8"
          />
        </svg>
      </button>
    </header>
  )
}

export default Header
