import React from 'react'
import * as yup from 'yup'

export const SECTION_HEADING_TEXT = 'Hot NFTs'

export const searchParamsSchema = yup.object({
  pageSize: yup.mixed().oneOf(['12', '24', '48']),
  periodFilter: yup.mixed().oneOf(['24h', '1w']),
  page: yup
    .string()
    .matches(/^\d+$/)
    .test((value: any) => (value ? parseInt(value, 10) >= 1 : false)),
})

interface InitialSettings {
  [index: string]: string
}

export const INITIAL_SETTINGS: InitialSettings = {
  pageSize: '12',
  page: '1',
  periodFilter: '1w',
}

export const PERIOD_FILTER_TABS = [
  { key: '24h', text: '24H' },
  { key: '1w', text: '1W', isActive: true },
]

export const PAGE_SIZE_FILTER_TABS = [
  { key: '12', text: '12', isActive: true },
  { key: '24', text: '24' },
  { key: '48', text: '48' },
]

export const HOT_NFTS_GRID_PAGE_SIZE = 8
export const PERIOD_24H = <>24&nbsp;hours</>
export const PERIOD_1W = <>7&nbsp;days</>
