import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'

import { QueryKey } from '~api'
import { Token } from '~api/types'
import { TOKEN_BALANCE_DATA_MOCK } from '~api/mocks'
import TreemapStylized from '~components/TreemapStylized'
import SectionHeader from '~components/SectionHeader'
import useMatchMedia from '~hooks/useMatchMedia'

import { EXTRA_CHART_OPTIONS, groupByPrice } from './utils'
import { getLeadText, TEXT_PAGE_HEADER } from './constants'

import * as style from './SectionCurrenciesHeld.module.scss'

interface SectionCurrenciesHeldProps {
  className?: string
}

const SectionCurrenciesHeld: React.FC<SectionCurrenciesHeldProps> = (props) => {
  const { className, ...rest } = props

  const { data, dataUpdatedAt, refetch, isRefetching, isLoading } = useQuery<
    Token[]
  >([QueryKey.Summary.TOKENS_HELD], () => TOKEN_BALANCE_DATA_MOCK, {
    refetchInterval: false,
  })

  const treemapData = useMemo(
    () =>
      data?.map((token) => ({
        name: token.symbol,
        value: token.price * token.amount,
      })),
    [data]
  )

  const isMobile = useMatchMedia('(max-width: 639.98px)')

  const groupedData = useMemo(
    () =>
      isMobile
        ? groupByPrice(
            treemapData,
            [100_000, 200_000, 500_000, 1_000_000, 5_000_000, 10_000_000],
            [2, 3, 4, 5, 8, 16]
          )
        : groupByPrice(treemapData),
    [treemapData, isMobile]
  )

  return (
    <main
      {...rest}
      className={classNames(
        style.sectionCurrenciesHeld,
        'placeholder-glow',
        className
      )}
    >
      <SectionHeader
        headingText={TEXT_PAGE_HEADER}
        leadText={getLeadText(treemapData?.length)}
        isLoading={isLoading}
      />
      <TreemapStylized
        data={groupedData}
        extraChartOptions={EXTRA_CHART_OPTIONS}
        className={style.chartContainer}
        onRefetchClick={refetch}
        isRefetching={isRefetching || isLoading}
        dataUpdatedAt={dataUpdatedAt}
      />
    </main>
  )
}

export default SectionCurrenciesHeld
