import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import RouteURL from '~routes'

import SectionTokenBalance from './components/SectionTokenBalance'
import SectionLpTokens from './components/SectionLpTokens'

const DeFi = () => {
  return (
    <Routes>
      <Route
        path={RouteURL.Defi.TOTAL_BALANCE}
        element={<SectionTokenBalance />}
      />
      <Route
        path={`${RouteURL.DEFI}/:sectionId`}
        element={<SectionLpTokens />}
      />
      {/* Global fallback for non-existent routes */}
      <Route path="*" element={<Navigate to={RouteURL.DEFI} replace />} />
    </Routes>
  )
}

export default DeFi
