import React from 'react'
import classNames from 'classnames'

import { Ordering } from '~utils/enums'

import * as style from './TableHead.module.scss'

export interface Heading {
  text: string
  sortKey?: string
}

interface TableHeadProps {
  tableHead: Heading[]
  sortParams: { sortBy: string; sortOrder: string }
  setSortParams: Function
  className?: string
}

const TableHead: React.FC<TableHeadProps> = (props) => {
  const { tableHead, sortParams, setSortParams, className, ...rest } = props

  const handleHeadingClick = (index: number) => {
    const { sortKey } = tableHead[index]
    // Inverts the sort order if the current sort field is clicked. Otherwise returns 'desc'.
    const sort =
      Object.values(Ordering)[
        +(sortParams.sortBy === sortKey
          ? sortParams.sortOrder === Ordering.DESC
          : 0)
      ]

    setSortParams({ sortBy: sortKey, sortOrder: sort })
  }

  return (
    <thead {...rest} className={classNames(style.tableHeader, className)}>
      <tr>
        {tableHead.map(({ sortKey, text }, index) => (
          <th key={text}>
            {sortKey ? (
              <button
                type="button"
                className={classNames(style.btn, {
                  [style[sortParams.sortOrder]]: sortParams.sortBy === sortKey,
                })}
                onClick={() => handleHeadingClick(index)}
              >
                {text}
              </button>
            ) : (
              <span className={style.padding}>{text}</span>
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHead
