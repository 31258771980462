import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import Icon from '~components/Icon'

import { TEXT_UPDATING, toRelativeTime } from './utils'

import * as style from './ButtonUpdate.module.scss'

interface ButtonUpdateProps {
  className?: string
  isRefetching?: boolean
  dataUpdatedAt?: number
  [otherProps: string]: any
}

const ButtonUpdate: React.FC<ButtonUpdateProps> = (props) => {
  const { className, dataUpdatedAt, isRefetching = true, ...rest } = props

  const [dataFreshnessText, setDataFreshnessText] =
    useState<string>(TEXT_UPDATING)

  const updateTextInterval = useRef<any>(undefined)

  useEffect(() => {
    setDataFreshnessText(
      !dataUpdatedAt || isRefetching
        ? TEXT_UPDATING
        : toRelativeTime(dataUpdatedAt)
    )

    if (updateTextInterval.current) clearInterval(updateTextInterval.current)

    updateTextInterval.current = setInterval(
      () => setDataFreshnessText(toRelativeTime(dataUpdatedAt)),
      30.25 * 1000
    )

    return () => clearInterval(updateTextInterval.current)
  }, [dataUpdatedAt, isRefetching])

  return (
    <button
      type="button"
      {...rest}
      className={classNames(style.buttonUpdate, className)}
    >
      {dataFreshnessText}
      <Icon
        name="icon-refresh"
        size={24}
        className={classNames({ [style.isRefetching]: isRefetching })}
      />
    </button>
  )
}

export default ButtonUpdate
