import React from 'react'
import { Nav } from 'react-bootstrap'

import updateActiveItem from '~utils/updateActiveItem'

export interface TabItem {
  key: string | number
  text: string
  isActive?: boolean
  [otherProps: string]: any
}

interface TabsFilterProps {
  className?: string
  tabs: TabItem[]
  setTabs?: Function
  activeTabKey?: string | number
  setActiveTabKey?: Function
}

// TODO: remove setTabs when all pages are done via URL parameters
const TabsFilter: React.FC<TabsFilterProps> = (props) => {
  const { tabs, setTabs, activeTabKey, setActiveTabKey, ...rest } = props

  const activeItem = tabs.find(({ isActive }) => isActive)
  const activeItemKey = activeTabKey || activeItem?.key || null

  const handleSelect = (eventKey: any) => {
    setActiveTabKey?.(eventKey)
    setTabs?.((prevState: TabItem[]) => updateActiveItem(prevState, eventKey))
  }

  return (
    <Nav
      {...rest}
      // @ts-ignore
      variant="tabs-filter"
      // @ts-ignore
      activeKey={activeItemKey}
      onSelect={handleSelect}
    >
      {tabs.map(({ key, text }) => (
        <Nav.Item key={key}>
          <Nav.Link eventKey={key}>
            <span>{text}</span>
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

export default TabsFilter
