import React from 'react'
import { Modal } from 'react-bootstrap'

import NavMenu from '~components/NavMenu'
import { FOOTER_LINKS } from '~utils/constants'

import * as style from './MobileNavBar.module.scss'

interface MobileNavBarProps {
  show: boolean
}

const MobileNavBar: React.FC<MobileNavBarProps> = (props) => {
  const { show } = props

  return (
    <Modal fullscreen show={show} backdrop={false} className="modal-menu">
      <Modal.Body className={style.modalWrapper}>
        <div className={style.menuWrapper}>
          <NavMenu />
        </div>
        <ul className={style.menuFooter}>
          {FOOTER_LINKS.map(({ name, link }) => (
            <li className={style.menuFooterItem} key={name}>
              <a
                className={style.menuFooterLink}
                target="_blank"
                rel="noreferrer"
                href={link}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  )
}

export default MobileNavBar
