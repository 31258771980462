export const QueryKey = {
  Defi: {
    WALLET_ACTIONS: '/wallet-actions',
  },
  NFTS: '/nft/stats',
  Summary: {
    WALLET_EVALUATION: '',
    TOKENS_HELD: '/wallet-tokens',
    HOT_NFTS: '/nft/buys',
    NFTS_HELD: '/nft/held',
  },
}
