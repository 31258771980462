import React, { ReactElement } from 'react'
import { Placeholder } from 'react-bootstrap'
import classNames from 'classnames'

import useMatchMedia from '~hooks/useMatchMedia'

import * as style from './SectionHeader.module.scss'

interface SectionHeaderProps {
  headingText: string
  leadText?: string | ReactElement
  filters?: ReactElement
  offcanvasFilters?: ReactElement
  isLoading?: boolean
  className?: string
}

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  const {
    headingText,
    leadText,
    filters,
    offcanvasFilters,
    isLoading,
    className,
    ...rest
  } = props

  const isUpMd = useMatchMedia('(min-width: 640px)')

  return (
    <div className={classNames(style.sectionHeader, className)} {...rest}>
      <div className={style.filtersWrapper}>
        <h1 className={classNames({ [style.title]: !leadText })}>
          {headingText}
        </h1>
        <div>
          {isUpMd ? (
            <div className={style.desktopFilters}>{filters}</div>
          ) : (
            offcanvasFilters
          )}
        </div>
      </div>
      {leadText &&
        (!isLoading ? (
          <p className={classNames('h5', style.leadText)}>{leadText}</p>
        ) : (
          <Placeholder className={style.leadPlaceholder} animation="glow">
            <Placeholder style={{ width: '60%' }} />
            {!isUpMd && <Placeholder style={{ width: '40%' }} />}
          </Placeholder>
        ))}
    </div>
  )
}

export default SectionHeader
