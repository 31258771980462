import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Accordion, Nav } from 'react-bootstrap'
import { AccordionEventKey } from 'react-bootstrap/AccordionContext'
import classNames from 'classnames'

import Icon from '~components/Icon'

import { NAV_ITEMS } from './constants'
import * as style from './NavMenu.module.scss'

interface NavMenuProps {
  className?: string
}

const getActiveNavItem = (pathname: string) => {
  return NAV_ITEMS.find(({ link, subItems }) =>
    !subItems?.length
      ? link === pathname
      : !!subItems.find((subItem) => subItem.link === pathname)
  )
}

const NavMenu: React.FC<NavMenuProps> = (props) => {
  const { className, ...rest } = props
  const { pathname } = useLocation()
  const [activeItem, setActiveItem] = useState<AccordionEventKey>(
    () => getActiveNavItem(pathname)?.text
  )

  const activeNavItem = getActiveNavItem(pathname)
  const activeSubItem = activeNavItem?.subItems?.find(
    ({ link }) => link === pathname
  )

  useEffect(() => {
    setActiveItem(activeNavItem?.text)
  }, [activeNavItem])

  return (
    <Accordion
      {...rest}
      className={className}
      activeKey={activeItem}
      onSelect={(e) => setActiveItem(e)}
    >
      {NAV_ITEMS.map(({ text, iconName, link, subItems }) => {
        return subItems?.length ? (
          <Accordion.Item eventKey={text} key={text}>
            <Accordion.Header
              className={classNames({ active: activeNavItem?.text === text })}
            >
              <Icon name={iconName} size={24} />
              {text}
            </Accordion.Header>
            <Accordion.Body>
              {/* @ts-ignore */}
              <Nav activeKey={activeSubItem?.text || ''} variant="main">
                {subItems.map((subItem) => (
                  <Nav.Link
                    key={subItem.text}
                    as={Link}
                    to={subItem.link}
                    eventKey={subItem.text}
                  >
                    <span>{subItem.text}</span>
                  </Nav.Link>
                ))}
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <Link
            key={text}
            to={link}
            className={classNames(style.menuItem, {
              [style.active]: activeNavItem?.text === text,
            })}
            onClick={() => setActiveItem(null)}
          >
            <Icon name={iconName} size={24} />
            {text}
          </Link>
        )
      })}
    </Accordion>
  )
}

export default NavMenu
