import React, { createRef, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Placeholder, Ratio, Table } from 'react-bootstrap'
import { throttle, round } from 'lodash'
import { useResizeDetector } from 'react-resize-detector'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import SectionHeader from '~components/SectionHeader'
import DropdownFilter from '~components/DropdownFilter'
import TabsFilter from '~components/TabsFilter'
import OffcanvasFilters from '~components/OffcanvasFilters'
import TableHead from '~components/TableHead'
import Paginator from '~components/Paginator'

import { getNFTsCollections, QueryKey } from '~api'
import { NftData } from '~api/types'
import validateSearchParams from '~utils/validateSearchParams'
import { Ordering } from '~utils/enums'

import {
  SECTION_HEADING_TEXT,
  TABLE_HEADER,
  PAGE_SIZE_FILTER_TABS,
  PERIOD_FILTER_TABS,
  NFTS_SKELETON_ROW_COUNT,
  searchParamsSchema,
  INITIAL_SETTINGS,
  IMG_SIZES,
} from './constants'
import NFTTableSkeleton from './components/NFTTableSkeleton'

import * as style from './NFTs.module.scss'

interface NFTsProps {
  className?: string
}

const NFTs: React.FC<NFTsProps> = (props) => {
  const { className, ...rest } = props

  const refTableWrapper = createRef<HTMLDivElement>()

  const [fillStickyCells, setFillStickyCells] = useState(false)
  const [hasScroll, setHasScroll] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_SETTINGS)
  const [pageSettings, setPageSettings] = useState(
    validateSearchParams(searchParams, searchParamsSchema, INITIAL_SETTINGS)
  )

  useEffect(() => {
    setSearchParams({ ...pageSettings })
  }, [pageSettings])

  // Set current page to state and url
  const handleChangePage = (pageRaw: string) => {
    window.scrollTo(0, 0)

    const page = (+pageRaw + 1).toString()
    setPageSettings((prevState) => ({ ...prevState, page }))
  }

  // Set page size/period filter to state and url
  const handleChangeSettings = (field: string, value: string) => {
    setPageSettings((prevState) => ({
      ...prevState,
      [field]: value,
      page: INITIAL_SETTINGS.page,
    }))
  }

  const handleSort = (params: { sortBy: string; sortOrder: Ordering }) => {
    setPageSettings((prevState) => ({
      ...prevState,
      ...params,
    }))
  }

  // Add fill to first to sticky columns on scroll
  const handleScroll = throttle((e: any) => {
    if (e.target.scrollLeft > 0) {
      setFillStickyCells(true)
      return
    }

    setFillStickyCells(false)
  }, 50)

  // Observe table scroll
  useEffect(() => {
    if (!refTableWrapper.current) return

    const tableWrapperEl = refTableWrapper.current

    tableWrapperEl.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    // eslint-disable-next-line consistent-return
    return () => tableWrapperEl.removeEventListener('scroll', handleScroll)
  }, [refTableWrapper, handleScroll])

  // Show right edge fade out if table scroll is present
  const onResize = useCallback(() => {
    if (!refTableWrapper.current) return

    const hasHorizontalScrollbar =
      refTableWrapper.current.scrollWidth > refTableWrapper.current.clientWidth

    setHasScroll(hasHorizontalScrollbar)
  }, [refTableWrapper])

  // Observe table resize
  useResizeDetector({
    targetRef: refTableWrapper,
    onResize,
    handleHeight: false,
  })

  const selectedPage = +pageSettings.page - 1
  const pageSize = +pageSettings.pageSize
  const isDayFilter = pageSettings.periodFilter === '24h'
  const { sortBy, sortOrder } = pageSettings

  const { data, isLoading } = useQuery<NftData>(
    [QueryKey.NFTS, sortBy, sortOrder, selectedPage, pageSize, isDayFilter],
    () =>
      getNFTsCollections(
        isDayFilter ? `${sortBy}1d` : `${sortBy}7d`,
        sortOrder,
        selectedPage,
        pageSize
      )
  )

  const pageCount = data ? Math.ceil(data.totalCount / pageSize) : 0

  useEffect(() => {
    if (data && selectedPage >= pageCount)
      setPageSettings((prevState) => ({
        ...prevState,
        page: pageCount.toString(),
      }))
  }, [selectedPage, pageCount])

  const offcanvasFilters = [
    {
      name: 'Period',
      tabs: PERIOD_FILTER_TABS,
      setActiveTabKey: (key: string) =>
        handleChangeSettings('periodFilter', key),
      activeTabKey: pageSettings.periodFilter,
    },
    {
      name: 'Rows per page',
      tabs: PAGE_SIZE_FILTER_TABS,
      setActiveTabKey: (key: string) => handleChangeSettings('pageSize', key),
      activeTabKey: pageSettings.pageSize,
    },
  ]

  return (
    <main {...rest} className={className}>
      <SectionHeader
        headingText={SECTION_HEADING_TEXT}
        filters={
          <>
            <DropdownFilter
              dropdownItems={PAGE_SIZE_FILTER_TABS}
              activeItemKey={pageSettings.pageSize}
              setActiveItemKey={(key: string) =>
                handleChangeSettings('pageSize', key)
              }
              label="Per page"
            />
            <TabsFilter
              tabs={PERIOD_FILTER_TABS}
              activeTabKey={pageSettings.periodFilter}
              setActiveTabKey={(key: string) =>
                handleChangeSettings('periodFilter', key)
              }
            />
          </>
        }
        offcanvasFilters={
          <OffcanvasFilters offcanvasFilters={offcanvasFilters} />
        }
      />
      <div
        className={classNames('table-responsive', style.tableWrapper, {
          [style.fadeOutRightEdge]: !fillStickyCells && hasScroll,
        })}
        ref={refTableWrapper}
      >
        <Table
          className={classNames(style.table, {
            [style.cellsFilled]: fillStickyCells,
          })}
        >
          <TableHead
            tableHead={TABLE_HEADER}
            sortParams={{ sortBy, sortOrder }}
            setSortParams={handleSort}
          />
          <tbody>
            {!isLoading ? (
              data?.stats?.map(
                (
                  {
                    buys1d,
                    buys7d,
                    buys_volume1d: buysVolume1d,
                    buys_volume7d: buysVolume7d,
                    listings1d,
                    listings7d,
                    sales1d,
                    sales7d,
                    sales_volume1d: salesVolume1d,
                    sales_volume7d: salesVolume7d,
                    nft,
                  },
                  index
                ) => (
                  <tr key={nft.update_authority}>
                    <td>{selectedPage * pageSize + index + 1}</td>
                    <td className={style.tdCollection}>
                      <div
                        className={classNames(
                          style.gridCollection,
                          'placeholder-glow'
                        )}
                      >
                        <Ratio aspectRatio="1x1" className={style.nftImg}>
                          <>
                            <Placeholder as="div" className={style.nftImg} />
                            <img
                              src={nft.image_uri}
                              alt="nft"
                              className={style.nftImg}
                              sizes={IMG_SIZES}
                            />
                          </>
                        </Ratio>
                        <span>{nft.collection}</span>
                      </div>
                    </td>
                    <td className={style.tdSymbol}>{nft.symbol}</td>
                    <td>{isDayFilter ? listings1d : listings7d}</td>
                    <td>{isDayFilter ? buys1d : buys7d}</td>
                    <td>
                      {round(isDayFilter ? buysVolume1d : buysVolume7d, 2)}
                    </td>
                    <td>{isDayFilter ? sales1d : sales7d}</td>
                    <td>
                      {round(isDayFilter ? salesVolume1d : salesVolume7d, 2)}
                    </td>
                  </tr>
                )
              )
            ) : (
              <NFTTableSkeleton rows={NFTS_SKELETON_ROW_COUNT} />
            )}
          </tbody>
        </Table>
      </div>
      {!isLoading && pageCount > 1 && (
        <Paginator
          pageCount={pageCount}
          selectedPage={selectedPage}
          setSelectedPage={handleChangePage}
        />
      )}
    </main>
  )
}

export default NFTs
