import React from 'react'
import classNames from 'classnames'
import { FormControl } from 'react-bootstrap'

import Icon from '~components/Icon'

import * as style from './InputSearch.module.scss'

interface InputSearchProps {
  className?: string
  searchQuery: string | undefined
  setSearchQuery: Function
}

const InputSearch: React.FC<InputSearchProps> = (props) => {
  const { className, searchQuery, setSearchQuery, ...rest } = props

  const handleClearQuery = () => setSearchQuery('')

  return (
    <form
      {...rest}
      className={classNames(style.inputSearch, className)}
      onSubmit={(e) => e.preventDefault()}
    >
      <FormControl
        placeholder="Token pair: SBR USDC"
        type="search"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button
        type="button"
        className={style.btnClear}
        disabled={!searchQuery}
        onClick={handleClearQuery}
        aria-label="Clear search query"
      >
        <Icon name="icon-trash" size={20} />
      </button>
    </form>
  )
}

export default InputSearch
