import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import useMatchMedia from '~hooks/useMatchMedia'
import Header from '~components/Header'
import NavMenu from '~components/NavMenu'
import Footer from '~components/Footer'
import MobileNavBar from '~components/MobileNavBar'

import * as style from './NavBar.module.scss'

interface NavBarProps {
  className?: string
}

const NavBar: React.FC<NavBarProps> = (props) => {
  const { className, ...rest } = props
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const isDesktop = useMatchMedia('(min-width: 1024px)')

  const { pathname } = useLocation()

  useEffect(() => {
    if (showMobileMenu) setShowMobileMenu(false)
  }, [pathname])

  useEffect(() => {
    if (isDesktop) setShowMobileMenu(false)
  }, [isDesktop])

  return (
    <aside {...rest} className={classNames(style.navBar, className)}>
      <Header
        isModalOpen={showMobileMenu}
        handleShowModal={() => setShowMobileMenu((prevState) => !prevState)}
      />
      {isDesktop && (
        <>
          <NavMenu />
          <Footer />
        </>
      )}
      <MobileNavBar show={showMobileMenu} />
    </aside>
  )
}

export default NavBar
