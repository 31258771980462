import React from 'react'
import { Placeholder } from 'react-bootstrap'

import * as style from './NFTTableSkeleton.module.scss'

interface NFTTableSkeletonProps {
  rows: number
}

const NFTTableSkeleton: React.FC<NFTTableSkeletonProps> = ({ rows }) => (
  <>
    {Array.from({ length: rows }, (_, index) => (
      <tr key={index} className="placeholder-glow">
        <td>
          <Placeholder style={{ width: '20px' }} />
        </td>
        <td>
          <div className={style.colImg}>
            <Placeholder as="div" className={style.img} />
            <Placeholder style={{ width: '90px' }} />
          </div>
        </td>
        {Array.from({ length: 6 }, (__, innerIndex) => (
          <td key={`${index}-${innerIndex}`}>
            <Placeholder style={{ width: '55px' }} />
          </td>
        ))}
      </tr>
    ))}
  </>
)

export default NFTTableSkeleton
