import React from 'react'
import { Ratio, Placeholder } from 'react-bootstrap'
import classNames from 'classnames'

import Icon from '~components/Icon'

import * as style from './NftCard.module.scss'

interface NftCardProps {
  collection?: string
  imgSrc?: string
  purchases?: number
  volume?: number
  isLoading?: boolean
  isDayFilter?: boolean
  className?: string
  imgixClient?: any
}

const NftCard: React.FC<NftCardProps> = (props) => {
  const {
    collection,
    purchases,
    volume,
    imgSrc,
    isLoading,
    isDayFilter,
    className,
    imgixClient,
    ...rest
  } = props

  const period = isDayFilter ? '(24H)' : '(7D)'

  return (
    <div
      {...rest}
      className={classNames(style.nftCard, 'placeholder-glow', className)}
    >
      <Ratio aspectRatio="1x1">
        <>
          <Placeholder as="div" className={style.nftImg} />
          <img alt={collection} src={imgSrc} className={style.nftImg} />
        </>
      </Ratio>
      <div className={style.nftInfo}>
        {!isLoading ? (
          <>
            {!!collection && (
              <h3 className={style.nftInfoTitle}>{collection}</h3>
            )}
            <div className={style.infoItem}>
              <div className={style.infoItemTitle}>{`Purchases ${period}`}</div>
              <div className={style.infoItemValue}>{purchases}</div>
            </div>
            <div className={style.infoItem}>
              <div className={style.infoItemTitle}>{`Volume ${period}`}</div>
              <div className={style.itemWithIcon}>
                <Icon name="icon-solana" size={24} isFromInlineDefs />
                <div className={style.infoItemValue}>{volume}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Placeholder
              as="h3"
              className={classNames(
                style.nftInfoTitle,
                style.placeholderPadding
              )}
              style={{ width: '150px' }}
            />
            <div className={classNames(style.infoItem, style.placeholderItem)}>
              <Placeholder style={{ width: '93px' }} />
              <Placeholder style={{ width: '20px' }} />
            </div>
            <div
              className={classNames(
                style.infoItem,
                style.placeholderItem,
                style.placeholderPadding
              )}
            >
              <Placeholder style={{ width: '77px' }} />
              <Placeholder style={{ width: '50px' }} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

NftCard.defaultProps = {
  isLoading: false,
}

export default NftCard
