import React from 'react'
import classNames from 'classnames'

import { FOOTER_LINKS } from '~utils/constants'
import Copyright from '~components/Copyright'

import * as style from './Footer.module.scss'

interface FooterProps {
  className?: string
}

const Footer: React.FC<FooterProps> = (props) => {
  const { className, ...rest } = props

  return (
    <footer {...rest} className={classNames(style.footer, className)}>
      <ul className={style.footerNav}>
        {FOOTER_LINKS.map(({ name, link }) => (
          <li className={style.footerNavItem} key={name}>
            <a
              className={style.footerNavLink}
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={link}
            >
              {name}
            </a>
          </li>
        ))}
      </ul>
      <Copyright />
    </footer>
  )
}

export default Footer
