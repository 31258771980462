// extracted by mini-css-extract-plugin
export var infoItem = "NftCard_infoItem__nuSAG";
export var infoItemTitle = "NftCard_infoItem_title__Ed+JY";
export var infoItemValue = "NftCard_infoItem_value__cFNlG";
export var itemWithIcon = "NftCard_itemWithIcon__fZNWY";
export var nftCard = "NftCard_nftCard__1ZMSN";
export var nftImg = "NftCard_nftImg__jPIeZ";
export var nftInfo = "NftCard_nftInfo__rVsEu";
export var nftInfoTitle = "NftCard_nftInfo_title__5Q2qx";
export var placeholderItem = "NftCard_placeholderItem__zOix0";
export var placeholderPadding = "NftCard_placeholderPadding__3K0Jl";