/* eslint-disable camelcase */
import axios from 'axios'
import { QueryClient } from 'react-query'
import { chunk, orderBy } from 'lodash'

import { Ordering } from '~utils/enums'

import {
  HOT_NFTS_MOCK,
  NFT_DATA_MOCK,
  NFT_HELD_DATA_MOCK,
  TOKEN_DATA_MOCK,
} from './mocks'
import { QueryKey } from './constants'

export { QueryKey }

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

// @ts-ignore
const defaultQueryFn = async ({ queryKey }) => {
  const res = api.get(queryKey[0]).then((response) => response.data)

  return res
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchInterval: 60 * 1000, // 60s
      refetchOnWindowFocus: false,
      retry: false, // after getting error disable auto-retry
    },
  },
})

export const getNFTsCollections = (
  order_by: string,
  order_direction: string,
  selectedPage: number,
  limit: number
) => {
  const orderedData = orderBy(
    NFT_DATA_MOCK,
    order_by,
    order_direction as Ordering
  )
  const pages = chunk(orderedData, limit)

  return { stats: pages[selectedPage], totalCount: NFT_DATA_MOCK.length }
}

export const getHotNFTs = () => HOT_NFTS_MOCK

export const getNFTsHeld = () => NFT_HELD_DATA_MOCK

export const getTokens = (action: string) => TOKEN_DATA_MOCK[action]
