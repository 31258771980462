/* eslint-disable camelcase */
import { Nft, Pair, PairOrder, Token, TokenPair, HotNft } from './types'

import nftImg1 from '../assets/nft/nft_01.jpg'
import nftImg2 from '../assets/nft/nft_02.jpg'
import nftImg3 from '../assets/nft/nft_03.jpg'
import nftImg4 from '../assets/nft/nft_04.jpg'
import nftImg5 from '../assets/nft/nft_05.jpg'
import nftImg6 from '../assets/nft/nft_06.jpg'
import nftImg7 from '../assets/nft/nft_07.jpg'
import nftImg8 from '../assets/nft/nft_08.jpg'
import nftImg9 from '../assets/nft/nft_09.jpg'
import nftImg10 from '../assets/nft/nft_10.jpg'

import token from '../assets/tokens/token.png'
import soFTT from '../assets/tokens/so_ftt.png'
import USDC from '../assets/tokens/usdc.png'
import SOL from '../assets/tokens/sol.png'
import mSol from '../assets/tokens/m_sol.png'
import renBtc from '../assets/tokens/ren_btc.png'
import ETH from '../assets/tokens/eth.png'
import stSOL from '../assets/tokens/st_sol.png'
import POLIS from '../assets/tokens/polis.png'
import LDO from '../assets/tokens/ldo.png'
import JSOL from '../assets/tokens/j_sol.png'
import MEDIA from '../assets/tokens/media.png'
import JET from '../assets/tokens/jet.png'
import MNDE from '../assets/tokens/mnde.png'
import REAL from '../assets/tokens/real.png'
import FIDA from '../assets/tokens/fida.png'

import hotNft1 from '../assets/hotNft/nft_01.png'
import hotNft2 from '../assets/hotNft/nft_02.png'
import hotNft3 from '../assets/hotNft/nft_03.png'
import hotNft4 from '../assets/hotNft/nft_04.png'
import hotNft5 from '../assets/hotNft/nft_05.png'
import hotNft6 from '../assets/hotNft/nft_06.png'
import hotNft7 from '../assets/hotNft/nft_07.png'
import hotNft8 from '../assets/hotNft/nft_08.png'

export const NFT_DATA_MOCK: Nft[] = [
  {
    symbol: 'AUROR',
    listings1d: 12,
    listings7d: 45,
    sales1d: 23,
    sales7d: 10,
    sales_volume1d: 16,
    sales_volume7d: 67,
    buys1d: 22,
    buys7d: 25,
    buys_volume1d: 3,
    buys_volume7d: 8,
    nft: {
      collection: 'Aurorian',
      image_uri: nftImg1,
      update_authority: 'Aurorian authority',
      symbol: 'AUROR',
    },
  },
  {
    symbol: 'enviro',
    listings1d: 1,
    listings7d: 5,
    sales1d: 5,
    sales7d: 16,
    sales_volume1d: 12,
    sales_volume7d: 47,
    buys1d: 2,
    buys7d: 5,
    buys_volume1d: 32,
    buys_volume7d: 81,
    nft: {
      collection: 'enviroPass | Tier 03',
      image_uri: nftImg2,
      update_authority: 'enviroPass authority',
      symbol: 'enviro',
    },
  },
  {
    symbol: 'CSAMURAI',
    listings1d: 12,
    listings7d: 53,
    sales1d: 51,
    sales7d: 162,
    sales_volume1d: 122,
    sales_volume7d: 471,
    buys1d: 23,
    buys7d: 54,
    buys_volume1d: 3,
    buys_volume7d: 8,
    nft: {
      collection: 'Cyber Samurai Genesis Collection',
      image_uri: nftImg3,
      update_authority: 'Cyber authority',
      symbol: 'CSAMURAI',
    },
  },
  {
    symbol: 'TFF',
    listings1d: 1,
    listings7d: 3,
    sales1d: 5,
    sales7d: 12,
    sales_volume1d: 12,
    sales_volume7d: 41,
    buys1d: 3,
    buys7d: 4,
    buys_volume1d: 3,
    buys_volume7d: 8,
    nft: {
      collection: 'Transdimensional Fox Federation',
      image_uri: nftImg4,
      update_authority: 'Transdimensional authority',
      symbol: 'TFF',
    },
  },
  {
    symbol: 'Meerkat',
    listings1d: 16,
    listings7d: 37,
    sales1d: 21,
    sales7d: 32,
    sales_volume1d: 22,
    sales_volume7d: 41,
    buys1d: 32,
    buys7d: 49,
    buys_volume1d: 31,
    buys_volume7d: 87,
    nft: {
      collection: 'Meerkat Millionaires Country Club',
      image_uri: nftImg5,
      update_authority: 'Meerkat authority',
      symbol: 'Meerkat',
    },
  },
  {
    symbol: 'GGSG',
    listings1d: 6,
    listings7d: 7,
    sales1d: 1,
    sales7d: 2,
    sales_volume1d: 2,
    sales_volume7d: 4,
    buys1d: 2,
    buys7d: 9,
    buys_volume1d: 3,
    buys_volume7d: 8,
    nft: {
      collection: 'Galactic Gecko Space Garage',
      image_uri: nftImg6,
      update_authority: 'Galactic authority',
      symbol: 'GGSG',
    },
  },
  {
    symbol: 'SOLGods',
    listings1d: 64,
    listings7d: 79,
    sales1d: 12,
    sales7d: 22,
    sales_volume1d: 26,
    sales_volume7d: 44,
    buys1d: 23,
    buys7d: 91,
    buys_volume1d: 39,
    buys_volume7d: 89,
    nft: {
      collection: 'SOLGods',
      image_uri: nftImg7,
      update_authority: 'SOLGods authority',
      symbol: 'SOLGods',
    },
  },
  {
    symbol: 'Hellhounds',
    listings1d: 42,
    listings7d: 56,
    sales1d: 22,
    sales7d: 25,
    sales_volume1d: 26,
    sales_volume7d: 26,
    buys1d: 23,
    buys7d: 91,
    buys_volume1d: 39,
    buys_volume7d: 89,
    nft: {
      collection: 'Hellhounds',
      image_uri: nftImg8,
      update_authority: 'Hellhounds authority',
      symbol: 'Hellhounds',
    },
  },
  {
    symbol: 'MAG',
    listings1d: 42,
    listings7d: 56,
    sales1d: 22,
    sales7d: 25,
    sales_volume1d: 26,
    sales_volume7d: 26,
    buys1d: 23,
    buys7d: 91,
    buys_volume1d: 39,
    buys_volume7d: 89,
    nft: {
      collection: 'The Meta Ape Gang',
      image_uri: nftImg9,
      update_authority: 'The Meta authority',
      symbol: 'MAG',
    },
  },
  {
    symbol: 'SBT',
    listings1d: 12,
    listings7d: 36,
    sales1d: 22,
    sales7d: 25,
    sales_volume1d: 26,
    sales_volume7d: 26,
    buys1d: 23,
    buys7d: 91,
    buys_volume1d: 39,
    buys_volume7d: 89,
    nft: {
      collection: 'SBT',
      image_uri: nftImg10,
      update_authority: 'SBT authority',
      symbol: 'SBT',
    },
  },
  {
    symbol: 'SBTC',
    listings1d: 1,
    listings7d: 3,
    sales1d: 2,
    sales7d: 2,
    sales_volume1d: 2,
    sales_volume7d: 2,
    buys1d: 2,
    buys7d: 9,
    buys_volume1d: 3,
    buys_volume7d: 8,
    nft: {
      collection: 'SBTC',
      image_uri: nftImg2,
      update_authority: 'SBTC authority',
      symbol: 'SBTC',
    },
  },
]

export const TOKEN_BALANCE_DATA_MOCK: Token[] = [
  {
    coingeckoId: 'soFtt',
    symbol: 'soFtt',
    logoURI: soFTT,
    price: 1.2,
    amount: 24_768,
  },
  {
    coingeckoId: 'USDC',
    symbol: 'USDC',
    logoURI: USDC,
    price: 2,
    amount: 14_014,
  },
  {
    coingeckoId: 'SOL',
    symbol: 'SOL',
    logoURI: SOL,
    price: 1.8,
    amount: 30_558,
  },
  {
    coingeckoId: 'BTC',
    symbol: 'BTC',
    logoURI: token,
    price: 2.2,
    amount: 59_292,
  },
  {
    coingeckoId: 'mSol',
    symbol: 'mSol',
    logoURI: mSol,
    price: 1.9,
    amount: 57_324,
  },
  {
    coingeckoId: 'renBtc',
    symbol: 'renBtc',
    logoURI: renBtc,
    price: 2.3,
    amount: 32_783,
  },
  {
    coingeckoId: 'soETH',
    symbol: 'soETH',
    logoURI: token,
    price: 0.8,
    amount: 29_214,
  },
  {
    coingeckoId: 'ETH',
    symbol: 'ETH',
    logoURI: ETH,
    price: 0.7,
    amount: 23_728,
  },
  {
    coingeckoId: 'USDT',
    symbol: 'USDT',
    logoURI: token,
    price: 1.6,
    amount: 22_275,
  },
  {
    coingeckoId: 'stSOL',
    symbol: 'stSOL',
    logoURI: stSOL,
    price: 3.1,
    amount: 18_848,
  },
  {
    coingeckoId: 'ATLAS',
    symbol: 'ATLAS',
    logoURI: token,
    price: 0.7,
    amount: 18_844,
  },
  {
    coingeckoId: 'PAI',
    symbol: 'PAI',
    logoURI: token,
    price: 3.2,
    amount: 14_980,
  },
  {
    coingeckoId: 'POLIS',
    symbol: 'POLIS',
    logoURI: POLIS,
    price: 0.6,
    amount: 12_349,
  },
  {
    coingeckoId: 'LDO',
    symbol: 'LDO',
    logoURI: LDO,
    price: 3.3,
    amount: 11_081,
  },
  {
    coingeckoId: 'JSOL',
    symbol: 'JSOL',
    logoURI: JSOL,
    price: 2.6,
    amount: 9_225,
  },
  {
    coingeckoId: 'MEDIA',
    symbol: 'MEDIA',
    logoURI: MEDIA,
    price: 2.4,
    amount: 8_451,
  },
  {
    coingeckoId: 'JET',
    symbol: 'JET',
    logoURI: JET,
    price: 2.6,
    amount: 18_114,
  },
  {
    coingeckoId: 'MNDE',
    symbol: 'MNDE',
    logoURI: MNDE,
    price: 3.44,
    amount: 15_693,
  },
  {
    coingeckoId: 'REAL',
    symbol: 'REAL',
    logoURI: REAL,
    price: 1.9,
    amount: 15_529,
  },
  {
    coingeckoId: 'REAL',
    symbol: 'FIDA',
    logoURI: FIDA,
    price: 1.3,
    amount: 15_227,
  },
]

const TOKEN_PAIR_MOCK: Pair[] = [
  {
    a_token: 'Orca',
    b_token: 'USDC',
    a_amount: 33099.94,
    b_amount: 2078.79,
    a_amount_day: 330.4,
    b_amount_day: 20.7,
    a_amount_week: 2099.22,
    b_amount_week: 99.24,
    lend: 78.4,
    lend_day: 0.4,
    lend_week: 9.22,
    borrow: 8.4,
    borrow_day: 11.4,
    borrow_week: 20.7,
    stake: 56.5,
    stake_day: 33.7,
    stake_week: 56.6,
    lp_amount: 1743.69,
    lp_amount_day: 43.7,
    lp_amount_week: 56.6,
    total_value: 1234.56,
    total_value_day: 12_789.62,
    total_value_week: 56_445.63321,
  },
  {
    a_token: 'Saber',
    b_token: 'USDC',
    a_amount: 33099.94,
    b_amount: 2078.79,
    a_amount_day: 330.4,
    b_amount_day: 20.7,
    a_amount_week: 2099.22,
    b_amount_week: 99.24,
    lend: 78.4,
    lend_day: 0.4,
    lend_week: 9.22,
    borrow: 8.4,
    borrow_day: 11.4,
    borrow_week: 20.7,
    stake: 56.5,
    stake_day: 33.7,
    stake_week: 56.6,
    lp_amount: 1743.69,
    lp_amount_day: 43.7,
    lp_amount_week: 56.6,
    total_value: 1234.56,
    total_value_day: 11_581.29,
    total_value_week: 44_656.9981,
  },
  {
    a_token: 'Raydium',
    b_token: 'USDC',
    a_amount: 33099.94,
    b_amount: 2078.79,
    a_amount_day: 330.4,
    b_amount_day: 20.7,
    a_amount_week: 2099.22,
    b_amount_week: 99.24,
    lend: 78.4,
    lend_day: 0.4,
    lend_week: 9.22,
    borrow: 8.4,
    borrow_day: 11.4,
    borrow_week: 20.7,
    stake: 56.5,
    stake_day: 33.7,
    stake_week: 56.6,
    lp_amount: 1743.69,
    lp_amount_day: 43.7,
    lp_amount_week: 56.6,
    total_value: 1234.56,
    total_value_day: 3_812.35,
    total_value_week: 33_122.9981,
  },
]

export const PAIR_ORDER_TOKENS_MOCK: PairOrder[] = [
  {
    name: 'MNGO-PERP',
    sell: {
      total_amount: 1_342.3,
      total_val: 12_878.89,
    },
    buy: {
      total_amount: 2_342.3,
      total_val: 15_878.89,
    },
  },
  {
    name: 'BTC-PERP',
    sell: {
      total_amount: 2_878.5,
      total_val: 3_878.89,
    },
    buy: {
      total_amount: 1_789.7,
      total_val: 8_878.89,
    },
  },
  {
    name: 'SOL-PERP',
    sell: {
      total_amount: 342.3,
      total_val: 878.89,
    },
    buy: {
      total_amount: 742.3,
      total_val: 5_878.89,
    },
  },
  {
    name: 'FTT-PERP',
    sell: {
      total_amount: 3_242.3,
      total_val: 2_879.89,
    },
    buy: {
      total_amount: 9_342.3,
      total_val: 20_878.89,
    },
  },
]

const LP_TOKENS_MOCK: TokenPair = {
  Orca: TOKEN_PAIR_MOCK,
  Raydium: TOKEN_PAIR_MOCK,
  Saber: TOKEN_PAIR_MOCK,
}

const FARM_TOKENS_MOCK: TokenPair = {
  Raydium: TOKEN_PAIR_MOCK,
  Francium: TOKEN_PAIR_MOCK,
  Saber: TOKEN_PAIR_MOCK,
}

const LEND_TOKENS_MOCK: TokenPair = {
  Francium: TOKEN_PAIR_MOCK,
  Larix: TOKEN_PAIR_MOCK,
  Mango: TOKEN_PAIR_MOCK,
}

const BORROW_TOKENS_MOCK: TokenPair = {
  Larix: TOKEN_PAIR_MOCK,
}

const STAKING_TOKENS_MOCK: TokenPair = {
  Marinade: TOKEN_PAIR_MOCK,
}

const VAULT_TOKENS_MOCK: TokenPair = {
  Katana: TOKEN_PAIR_MOCK,
}

const SWAP_TOKENS_MOCK: TokenPair = {
  Serum: TOKEN_PAIR_MOCK,
  Mango: TOKEN_PAIR_MOCK,
}

const SPOT_ORDER_TOKENS_MOCK: TokenPair = {
  Serum: PAIR_ORDER_TOKENS_MOCK,
  Mango: PAIR_ORDER_TOKENS_MOCK,
}

const PERPETUAL_ORDER_TOKENS_MOCK: TokenPair = {
  Mango: PAIR_ORDER_TOKENS_MOCK,
}

export const TOKEN_DATA_MOCK: { [key: string]: TokenPair } = {
  liquidity: LP_TOKENS_MOCK,
  farm: FARM_TOKENS_MOCK,
  lend: LEND_TOKENS_MOCK,
  borrow: BORROW_TOKENS_MOCK,
  staking: STAKING_TOKENS_MOCK,
  vault: VAULT_TOKENS_MOCK,
  swap: SWAP_TOKENS_MOCK,
  spotOrder: SPOT_ORDER_TOKENS_MOCK,
  perpOrder: PERPETUAL_ORDER_TOKENS_MOCK,
}

export const HOT_NFTS_MOCK: HotNft[] = [
  {
    buys1d: 16,
    buys7d: 56,
    buys_volume1d: 6.4,
    buys_volume7d: 22.4,
    nft: {
      collection: '#2524',
      image_uri: hotNft1,
      update_authority: '#2524',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 31,
    buys7d: 44,
    buys_volume1d: 6.6,
    buys_volume7d: 8.8,
    nft: {
      collection: '#2525',
      image_uri: hotNft2,
      update_authority: '#2525',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 4,
    buys7d: 11,
    buys_volume1d: 1.6,
    buys_volume7d: 4.4,
    nft: {
      collection: '#2526',
      image_uri: hotNft3,
      update_authority: '#2526',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 24,
    buys7d: 40,
    buys_volume1d: 7.2,
    buys_volume7d: 24,
    nft: {
      collection: '#2527',
      image_uri: hotNft4,
      update_authority: '#2527',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 31,
    buys7d: 34,
    buys_volume1d: 21.7,
    buys_volume7d: 23.8,
    nft: {
      collection: '#2528',
      image_uri: hotNft5,
      update_authority: '#2528',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 11,
    buys7d: 22,
    buys_volume1d: 4.4,
    buys_volume7d: 8.8,
    nft: {
      collection: '#2529',
      image_uri: hotNft6,
      update_authority: '#2529',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 18,
    buys7d: 23,
    buys_volume1d: 5.94,
    buys_volume7d: 7.59,
    nft: {
      collection: '#2530',
      image_uri: hotNft7,
      update_authority: '#2530',
      symbol: 'Wise',
    },
  },
  {
    buys1d: 19,
    buys7d: 44,
    buys_volume1d: 13.87,
    buys_volume7d: 32.12,
    nft: {
      collection: '#2531',
      image_uri: hotNft8,
      update_authority: '#2531',
      symbol: 'Wise',
    },
  },
]

export const NFT_HELD_DATA_MOCK = {
  holdings: [
    {
      nft: {
        collection: '#2524',
      },
      tokens_held: 13_897.63,
    },
    {
      nft: {
        collection: '#2525',
      },
      tokens_held: 3_122.3,
    },
    {
      nft: {
        collection: '#2526',
      },
      tokens_held: 6_997.4,
    },
    {
      nft: {
        collection: '#2527',
      },
      tokens_held: 23_571.66,
    },
    {
      nft: {
        collection: '#2528',
      },
      tokens_held: 3_112.8,
    },
    {
      nft: {
        collection: '#2529',
      },
      tokens_held: 33_989.11,
    },
    {
      nft: {
        collection: '#2530',
      },
      tokens_held: 23_701.21,
    },
    {
      nft: {
        collection: '#2531',
      },
      tokens_held: 36_819.1,
    },
    {
      nft: {
        collection: '#2532',
      },
      tokens_held: 46_219.8,
    },
    {
      nft: {
        collection: '#2533',
      },
      tokens_held: 3_219.8,
    },
  ],
}
