import React, { useState } from 'react'
import classNames from 'classnames'
import { Offcanvas } from 'react-bootstrap'

import Icon from '~components/Icon'
import TabsFilter from '~components/TabsFilter'

import * as style from './OffcanvasFilters.module.scss'

interface OffcanvasFilter {
  name: string
  tabs: any
  setTabs?: Function
  activeTabKey?: string | number
  setActiveTabKey?: Function
}

interface OffcanvasFiltersProps {
  className?: string
  offcanvasFilters: OffcanvasFilter[]
}

// TODO: remove setTabs when all pages are done via URL parameters
const OffcanvasFilters: React.FC<OffcanvasFiltersProps> = (props) => {
  const { className, offcanvasFilters, ...rest } = props

  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  return (
    <>
      <button
        {...rest}
        type="button"
        onClick={handleShow}
        className={classNames(style.btnSettings, className)}
      >
        <Icon name="icon-settings" size={24} />
      </button>
      <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header>
          <Offcanvas.Title>Filter</Offcanvas.Title>
          <button
            type="button"
            className="offcanvas-btn-close"
            onClick={handleClose}
            title="Close filters"
            aria-label="Close filters"
          >
            <Icon name="icon-close" size={24} />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {offcanvasFilters.map(
            ({ name, tabs, setTabs, activeTabKey, setActiveTabKey }) => (
              <div key={name} className={style.filterGroup}>
                <span>{name}</span>
                <TabsFilter
                  tabs={tabs}
                  setTabs={setTabs}
                  activeTabKey={activeTabKey}
                  setActiveTabKey={setActiveTabKey}
                />
              </div>
            )
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default OffcanvasFilters
