import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { round, sum } from 'lodash'

import { getTokens, QueryKey } from '~api'
import { TokenPair } from '~api/types'
import { getImgUrl } from '~utils/constants'
import validateSearchParams from '~utils/validateSearchParams'

import SectionHeader from '~components/SectionHeader'
import TabsFilter from '~components/TabsFilter'
import OffcanvasFilters from '~components/OffcanvasFilters'

import {
  PERIOD_FILTER_TABS,
  AVAILABLE_SECTIONS,
  searchParamsSchema,
  INITIAL_SETTINGS,
  INITIAL_CARD_COUNT,
} from './constants'
import { getModalData, getTotalPrice } from './utils'
import TokenCard from './components/TokenCard'
import FarmPairModal from './components/FarmPairModal'
import * as style from './SectionLpTokens.module.scss'

interface SectionLpTokensProps {
  className?: string
}

const SectionLpTokens: React.FC<SectionLpTokensProps> = (props) => {
  const { className, ...rest } = props

  const [searchParams, setSearchParams] = useSearchParams(INITIAL_SETTINGS)
  const [pageSettings, setPageSettings] = useState(
    validateSearchParams(searchParams, searchParamsSchema, INITIAL_SETTINGS)
  )
  const [showModal, setShowModal] = useState(false)
  const [activeToken, setActiveToken] = useState('')

  // Get dynamic section id and validate
  const { sectionId = '' } = useParams()
  const section = AVAILABLE_SECTIONS[sectionId] || AVAILABLE_SECTIONS[0]

  // Fetch tokens data
  const { data = {}, isLoading } = useQuery<TokenPair>(
    [QueryKey.Defi.WALLET_ACTIONS, section.id],
    () => getTokens(section.id)
  )

  const handleFilterChange = (filter: string) => {
    setPageSettings({ periodFilter: filter })
  }

  const totalPrice = useMemo(
    () => getTotalPrice(data, pageSettings.periodFilter),
    [pageSettings.periodFilter, data]
  )

  const totalSectionPrice = useMemo(
    () => sum(Object.values(totalPrice)),
    [totalPrice]
  )

  const modalData =
    getModalData(section, activeToken, pageSettings.periodFilter, data) || []

  const offcanvasFilters = [
    {
      name: 'Period',
      tabs: PERIOD_FILTER_TABS,
      activeTabKey: pageSettings.periodFilter,
      setActiveTabKey: handleFilterChange,
    },
  ]

  useEffect(() => {
    setSearchParams({ ...pageSettings }, { replace: true })
  }, [pageSettings, sectionId])

  const getConvertedPrice = (price: number) => round(price, 3).toLocaleString()

  const handleOpenModal = (name: string) => {
    setShowModal(true)
    setActiveToken(name)
  }

  return (
    <main {...rest} className={className}>
      <SectionHeader
        headingText={section.title}
        leadText={`$ ${getConvertedPrice(totalSectionPrice)}`}
        isLoading={isLoading}
        filters={
          !section.isOrderProtocol ? (
            <TabsFilter
              tabs={PERIOD_FILTER_TABS}
              activeTabKey={pageSettings.periodFilter}
              setActiveTabKey={handleFilterChange}
            />
          ) : undefined
        }
        offcanvasFilters={
          !section.isOrderProtocol ? (
            <OffcanvasFilters offcanvasFilters={offcanvasFilters} />
          ) : undefined
        }
      />
      <div className={style.tokensWrapper}>
        {!isLoading
          ? Object.keys(data).map((key) => (
              <TokenCard
                key={key}
                title={key}
                imgSrc={getImgUrl(`${key}_new`)}
                total={`$ ${getConvertedPrice(totalPrice[key])}`}
                onClick={() => handleOpenModal(key)}
              />
            ))
          : Array.from({ length: INITIAL_CARD_COUNT }, (_, key) => (
              <TokenCard key={key} isLoading />
            ))}
      </div>
      <FarmPairModal
        show={showModal}
        title={activeToken}
        imgSrc={getImgUrl(`${activeToken}_new`)}
        tableHeader={section.head}
        data={modalData}
        onHide={() => setShowModal(false)}
      />
    </main>
  )
}

export default SectionLpTokens
