import * as yup from 'yup'

export const SECTION_HEADING_TEXT = 'LP Tokens'

export const searchParamsSchema = yup.object({
  periodFilter: yup.mixed().oneOf(['1h', '24h', '1w']),
})

interface InitialSettings {
  [index: string]: string
}

export const INITIAL_SETTINGS: InitialSettings = { periodFilter: '1w' }

export const PERIOD_FILTER_TABS = [
  { key: '1h', text: 'Now', isActive: true },
  { key: '24h', text: '24H' },
  { key: '1w', text: '1W' },
]

export const PERIOD_FILTER_MAP: { [key: string]: string } = {
  '1h': '',
  '24h': '_day',
  '1w': '_week',
}

export const MODAL_TABLE_HEAD = [
  { text: 'Token' },
  { sortKey: 'total', text: 'Total' },
]
export const MODAL_TABLE_HEAD_SWAP = [
  { text: 'Token' },
  { text: '' },
  { sortKey: 'total', text: 'Rate' },
]
export const MODAL_TABLE_HEAD_AMOUNT = [
  { text: 'Token' },
  { sortKey: 'amount', text: 'Amount' },
  { sortKey: 'total', text: 'Total' },
]
export const MODAL_TABLE_HEAD_ORDER = [
  { text: 'Token' },
  { text: 'Amount' },
  { text: 'Volume' },
]

export const INITIAL_CARD_COUNT = 3
export const AVAILABLE_SECTIONS: { [key: string]: any } = {
  'lp-tokens': {
    id: 'liquidity',
    title: 'LP Tokens',
    head: MODAL_TABLE_HEAD,
  },
  farms: { id: 'farm', title: 'Farms', head: MODAL_TABLE_HEAD },
  lending: {
    id: 'lend',
    title: 'Lending',
    head: MODAL_TABLE_HEAD_AMOUNT,
    amountKey: 'lend',
  },
  borrowing: {
    id: 'borrow',
    title: 'Borrowing',
    head: MODAL_TABLE_HEAD_AMOUNT,
    amountKey: 'borrow',
  },
  staking: {
    id: 'staking',
    title: 'Staking',
    head: MODAL_TABLE_HEAD_AMOUNT,
    amountKey: 'stake',
  },
  vault: {
    id: 'vault',
    title: 'Vault',
    head: MODAL_TABLE_HEAD_AMOUNT,
    amountKey: 'lend',
  },
  swap: {
    id: 'swap',
    title: 'Swap',
    head: MODAL_TABLE_HEAD_SWAP,
    isSwap: true,
  },
  'spot-orders': {
    id: 'spotOrder',
    title: 'Spot Orders',
    head: MODAL_TABLE_HEAD_ORDER,
    isOrderProtocol: true,
  },
  'perpetual-orders': {
    id: 'perpOrder',
    title: 'Perpetual Orders',
    head: MODAL_TABLE_HEAD_ORDER,
    isOrderProtocol: true,
  },
}
