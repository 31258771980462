import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import './styles/app.scss'

import DeFi from '~containers/DeFi'
import SVGPreview from '~containers/SVGPreview'
import Summary from '~containers/Summary'
import NFTs from '~containers/NFTs'
import Layout from '~components/Layout'
import RouteURL from '~routes'
import buildUrl from '~utils/buildUrl'

const App = () => {
  const { pathname } = useLocation()

  // Restore scroll on location change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Layout>
      <Routes>
        {process.env.CF_PAGES_BRANCH !== 'master' && (
          <Route path="/___svg" element={<SVGPreview />} />
        )}
        <Route path={buildUrl([RouteURL.SUMMARY, '*'])} element={<Summary />} />
        <Route path={buildUrl([RouteURL.NFTS, '*'])}>
          <Route index element={<NFTs />} />
          <Route path="*" element={<Navigate to={RouteURL.NFTS} replace />} />
        </Route>
        {/* Fallback redirect inside this route */}
        <Route path={buildUrl([RouteURL.DEFI, '*'])} element={<DeFi />} />
      </Routes>
    </Layout>
  )
}

export default App
