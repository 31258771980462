import Highcharts, { DataLabelsFormatterCallbackFunction } from 'highcharts'

function labelFormatterL2Default() {
  // @ts-ignore
  const { point } = this

  // @ts-ignore
  if (point.parent === point.series.rootNode) {
    // @ts-ignore
    return `${point.name} (${point.value})`
  }

  return undefined
}

function tooltipFormatterDefault() {
  // @ts-ignore
  const { point } = this

  // @ts-ignore
  if (point.parent === point.series.rootNode) {
    // @ts-ignore
    return `<b>${point.name}:</b> ${point.value}`
  }

  return false
}

export interface ExtraChartOptions {
  tooltipFormatter?: Function
  labelFormatterL2?: DataLabelsFormatterCallbackFunction
  chartName?: string
}

const defaultOptions: ExtraChartOptions = {
  tooltipFormatter: tooltipFormatterDefault,
  labelFormatterL2: labelFormatterL2Default,
  chartName: 'NFTs Held Treemap',
}

export const getChartOptions = (
  data: any,
  {
    tooltipFormatter,
    labelFormatterL2,
    chartName,
  }: ExtraChartOptions = defaultOptions
): Highcharts.Options => ({
  credits: {
    enabled: false,
  },
  chart: {
    animation: false,
    type: 'treemap',
    backgroundColor: '#111213',
    borderRadius: 14,
    style: {
      fontFamily: 'inherit',
      overflow: 'visible',
    },
    spacing: [4, 4, 4, 4],
  },
  tooltip: {
    useHTML: true,
    backgroundColor: '#111213',
    borderWidth: 0,
    shadow: false,
    style: {
      color: 'white',
    },
    // @ts-ignore
    formatter: tooltipFormatter,
  },
  title: {
    text: '',
  },
  series: [
    {
      name: chartName,
      type: 'treemap',
      data,
      breadcrumbs: {
        floating: true,
        relativeTo: 'spacingBox',
        position: {
          y: -32,
          x: -4,
        },
        useHTML: true,
        buttonTheme: {
          fill: 'transparent',
          style: {
            color: 'white',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
          },
          states: {
            hover: {
              enabled: false,
              fill: 'transparent',
            },
            select: {
              enabled: false,
              style: {
                fill: 'transparent',
                color: 'white',
                pointerEvents: 'none',
              },
            },
          },
        },
      },
    },
  ],
  plotOptions: {
    treemap: {
      // colors: TREEMAP_COLORS,
      borderRadius: 10,
      // @ts-ignore
      colorByPoint: true,
      allowTraversingTree: true,
      layoutAlgorithm: 'squarified',
      layoutStartingDirection: 'horizontal',
      // animationLimit: 1000,
      opacity: 1,
      levelIsConstant: true,
      levels: [
        {
          level: 1,
          borderWidth: 4,
          borderColor: '#111213',
          dataLabels: {
            enabled: true,
            useHTML: true,
            align: 'left',
            verticalAlign: 'top',
            color: '#b5fdcc',
            style: {
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: '500',
              display: 'block',
            },
            x: 10,
            y: 10,
          },
        },
        {
          level: 2,
          borderWidth: 2,
          borderColor: '#111213',
          dataLabels: {
            enabled: true,
            style: {
              textOutline: '0.5px contrast',
            },
            formatter: labelFormatterL2,
          },
        },
      ],
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 608,
        },
        chartOptions: {
          plotOptions: {
            treemap: {
              levels: [
                {
                  level: 1,
                  layoutAlgorithm: 'stripes',
                  layoutStartingDirection: 'horizontal',
                  borderWidth: 4,
                  borderColor: '#111213',
                  dataLabels: {
                    enabled: true,
                    useHTML: true,
                    align: 'left',
                    verticalAlign: 'top',
                    color: '#b5fdcc',
                    style: {
                      fontSize: '10px',
                      lineHeight: '14px',
                      fontWeight: '500',
                      display: 'block',
                    },
                    x: 6,
                    y: 6,
                  },
                },
                {
                  level: 2,
                  layoutAlgorithm: 'stripes',
                  layoutStartingDirection: 'horizontal',
                  borderWidth: 2,
                  borderColor: '#111213',
                  dataLabels: {
                    enabled: true,
                    style: {
                      textOutline: '1px contrast',
                    },
                    formatter: labelFormatterL2,
                  },
                },
              ],
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
          },
        },
      },
    ],
  },
})
