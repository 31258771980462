import React from 'react'
import classNames from 'classnames'

import * as style from './Copyright.module.scss'

interface CopyrightProps {
  className?: string
}

const Copyright: React.FC<CopyrightProps> = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames(style.copyright, className)}>
      Designed & developed by{' '}
      <a
        href="https://min.studio"
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="The Minimal Studio"
      >
        min.studio
      </a>
    </div>
  )
}

export default Copyright
