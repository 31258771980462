import React from 'react'
import { round } from 'lodash'
import { PERIOD_1W, PERIOD_24H } from './constants'

export function getSubtitle(
  stats: any,
  collectionsCount: number,
  isDayFilter: boolean
) {
  if (!stats) return ''

  const count = isDayFilter ? stats.count1d : stats.count7d
  const volume = isDayFilter ? stats.volume1d : stats.volume7d

  return count === 0 ? (
    <>
      No NFTs purchased in the last&nbsp;{isDayFilter ? PERIOD_24H : PERIOD_1W}
    </>
  ) : (
    <>
      {count}&nbsp;NFTs purchased from {collectionsCount}&nbsp;collections
      for&nbsp;${round(volume, 2)}&nbsp;SOL in the last&nbsp;
      {isDayFilter ? PERIOD_24H : PERIOD_1W}
    </>
  )
}
