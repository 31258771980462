import { round, sumBy } from 'lodash'
import { TokenPair, Pair, PairOrder } from '~api/types'
import { PERIOD_FILTER_MAP } from './constants'

/* Get Total Price for each token in current section */
export const getTotalPrice = (data: TokenPair, activeTabKey: string) => {
  const total: any = {}

  Object.keys(data).forEach((key) => {
    total[key] = sumBy(data[key], (current) =>
      current.buy
        ? current.buy.total_val + current.sell.total_val
        : current[`total_value${PERIOD_FILTER_MAP[activeTabKey]}`]
    )
  })

  return total
}

const getAmount = (
  pair: Pair | PairOrder,
  type: string,
  activeTabKey: string
) => pair[`${type}_amount${PERIOD_FILTER_MAP[activeTabKey]}`]

/* Get modal data for swap section */
const getSwapProtocolData = (
  activeToken: string,
  activeTabKey: string,
  data: TokenPair
) =>
  data[activeToken]?.map((pair: Pair | PairOrder) => [
    [pair.a_token, pair.b_token],
    [
      round(getAmount(pair, 'a', activeTabKey), 4),
      round(getAmount(pair, 'b', activeTabKey), 4),
    ],
    round(
      getAmount(pair, 'a', activeTabKey) / getAmount(pair, 'b', activeTabKey),
      2
    ),
  ])

/* Get modal data for Spot Orders or Perpetual Orders sections */
const getOrderProtocolData = (
  activeToken: string,
  activeTabKey: string,
  data: TokenPair
) =>
  data[activeToken]?.map((pair: Pair | PairOrder) => [
    pair.name,
    [round(pair.buy.total_amount, 4), round(pair.sell.total_amount, 4)],
    [round(pair.buy.total_val, 4), round(pair.sell.total_val, 4)],
  ])

/* Get modal data for LP Tokens, Farms, Borrowing, Lending, Staking or Vault sections */
const getProtocolData = (
  activeToken: string,
  activeTabKey: string,
  data: TokenPair,
  section: any
) =>
  data[activeToken]?.map((pair: Pair | PairOrder) => [
    `${pair.a_token} ${pair.b_token}`,
    section.amountKey
      ? round(pair[`${section.amountKey}${PERIOD_FILTER_MAP[activeTabKey]}`], 4)
      : null,
    round(pair[`total_value${PERIOD_FILTER_MAP[activeTabKey]}`], 4),
  ])

export const getModalData = (
  section: any,
  activeToken: string,
  activeTabKey: string,
  data: TokenPair
) => {
  if (section.isSwap)
    return getSwapProtocolData(activeToken, activeTabKey, data)
  if (section.isOrderProtocol)
    return getOrderProtocolData(activeToken, activeTabKey, data)

  return getProtocolData(activeToken, activeTabKey, data, section)
}
