import React from 'react'
import { Placeholder } from 'react-bootstrap'

import * as style from './TokenBalanceSkeleton.module.scss'

interface TokenBalanceSkeletonProps {
  rows?: number
}

const TokenBalanceSkeleton: React.FC<TokenBalanceSkeletonProps> = ({
  rows = 10,
}) => (
  <>
    {Array.from({ length: rows }, (_, index) => (
      <tr key={index} className="placeholder-glow">
        <td>
          <div className={style.colImg}>
            <Placeholder as="div" className={style.img} />
            <Placeholder as="h5" style={{ width: '50px' }} />
          </div>
        </td>
        <td>
          <Placeholder style={{ width: '90px' }} />
        </td>
        <td>
          <Placeholder style={{ width: '90px' }} />
        </td>
      </tr>
    ))}
  </>
)

export default TokenBalanceSkeleton
