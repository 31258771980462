interface SearchData {
  [index: string]: string
}

const validateSearchParams = (
  searchParams: URLSearchParams,
  searchParamsSchema: any,
  initialParams: SearchData
) => {
  const validatedData: SearchData = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of searchParams.entries()) {
    try {
      // @ts-ignore
      validatedData[key] = searchParamsSchema.validateSyncAt(key, {
        [key]: value,
      })
    } catch {
      validatedData[key] = initialParams[key]
    }
  }

  return validatedData
}

export default validateSearchParams
