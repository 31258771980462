const RouteURL = {
  DEFI: '/',
  Defi: {
    TOTAL_BALANCE: '',
    LP_TOKENS: 'lp-tokens',
    FARMS: 'farms',
    LENDING: 'lending',
    BORROWING: 'borrowing',
    STAKING: 'staking',
    VAULT: 'vault',
    SWAP: 'swap',
    SPOT_ORDERS: 'spot-orders',
    PERPETUAL_ORDERS: 'perpetual-orders',
  },
  NFTS: '/nfts',
  SUMMARY: '/summary',
  Summary: {
    WALLET_EVALUATION: 'wallet-evaluation',
    CURRENCIES_HELD: 'currencies-held',
    HOT_NFTS_TREEMAP: 'hot-nfts-treemap',
    HOT_NFTS: 'hot-nfts',
    NFTS_HELD_TREEMAP: 'nfts-held-treemap',
  },
  ABOUT: 'https://wisewhales.xyz/#data',
  LITEPAPER:
    'https://colossal-quotation-3af.notion.site/Litepaper-4c19e9368c4f43f282c8e1ef0da69931#69a4445d16614c8bbc930079419ffe3e',
  FAQ: 'https://wisewhales.xyz/#faq',
}

export default RouteURL
