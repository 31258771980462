/* eslint-disable @typescript-eslint/default-param-last */
import { quantile as getQuantiles, range } from 'd3'
import { countBy } from 'lodash'
import { TreemapDataItem } from './types'

const categorize = (
  item: TreemapDataItem,
  quantileValues: number[]
): TreemapDataItem => {
  // Categorise as the first quantile
  if (item.value <= quantileValues[0]) {
    return { ...item, parent: `quantile-0`, quantile: 0 }
  }

  // Categorise as 2 and onward quantiles
  for (let i = 0; i < quantileValues.length - 1; i += 1) {
    if (item.value > quantileValues[i] && item.value <= quantileValues[i + 1]) {
      return {
        ...item,
        parent: `quantile-${i + 1}`,
        quantile: i + 1,
      }
    }
  }

  return item
}

const generateLabels = (
  dataWithParents: TreemapDataItem[],
  quantilesCount: number
) => {
  // count the amount of values by quantile
  /* {0: 3, 1: 2, 3: 4} */
  const itemCounts = countBy(dataWithParents, (item) => item.quantile)

  /* 4 -> [0, 1, 2, 3] */
  const quantileIndexes = range(quantilesCount)

  // add zeros for quantiles without values
  /* {0: 3, 1: 2, 2: 0, 3: 4} */
  /* [3, 2, 0, 4] */
  const counts: number[] = quantileIndexes.map(
    (quantileIndex) => itemCounts?.[quantileIndex] || 0
  )

  // convert counts array to the array of indexes at which each category ends
  const countsReverse = [...counts].reverse()

  const cumCountsReverse = [countsReverse[0]]

  for (let i = 1; i < countsReverse.length; i += 1) {
    cumCountsReverse[i] = cumCountsReverse[i - 1] + countsReverse[i]
  }

  const cumCounts = [...cumCountsReverse].reverse()

  // compose labels
  const labels = cumCounts.map((count, i) =>
    // If not last quantile and quantile contains more than one item
    cumCounts?.[i + 1] && cumCounts?.[i + 1] !== count - 1
      ? `Top ${cumCounts[i + 1] + 1} - ${count}`
      : `Top ${count}`
  )

  return { itemCounts, labels }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const groupDataByQuantiles = (
  data: TreemapDataItem[],
  quantilesCount = 4
) => {
  // array of objects to array of numbers
  const pureValues = data.map(({ value }) => value)

  // generate quantile numbers
  // 5 -> [1, 2, 3, 4, 5]
  const quantileNumbers = range(quantilesCount).map(
    (quantileNumber) => quantileNumber + 1
  )

  // get quantile values
  // [1, 2, 3, 4, 5] -> [ 1, 1, 3, 8, 168 ]
  const quantileValues = quantileNumbers.map(
    (quantileNumber) =>
      getQuantiles(pureValues, quantileNumber / quantilesCount)!!
  )

  // remove duplicates
  // [ 1, 1, 3, 8, 168 ] -> [ 1, 3, 8, 168 ]
  const uniqueQuantileValues = [...new Set(quantileValues)]

  // categorize values by quantiles
  // add `parent` and `quantile` properties to data
  const dataWithParents = data.map((item) =>
    categorize(item, uniqueQuantileValues)
  )

  // generate labels for top-level root quantile items
  const { itemCounts, labels } = generateLabels(
    dataWithParents,
    uniqueQuantileValues.length
  )

  // add top-level root quantile items to data array
  const dataComplete = [
    ...dataWithParents,
    ...uniqueQuantileValues.map(
      (quantileValue, index) =>
        ({
          name: labels[index],
          id: `quantile-${index}`,
          // Use index as the value for area. If no items in quantile, then 0
          value: itemCounts[index] ? index + 1 : 0,
        }) as TreemapDataItem
    ),
  ]

  return dataComplete
}
