import React from 'react'
import classNames from 'classnames'
import { Table } from 'react-bootstrap'

import { handleImgError } from '~utils/handleImgError'
import { Token } from '~api/types'
import TableHead, { Heading } from '~components/TableHead'

import TokenBalanceSkeleton from '../TokenBalanceSkeleton'

import * as style from './TokenBalanceTable.module.scss'

interface TokenBalanceTableProps {
  className?: string
  tableHeader: Heading[]
  isLoading: boolean
  onSort: Function
  pageInt: number
  paginatedData: Token[][]
  pageSettings: any
}

const TokenBalanceTable: React.FC<TokenBalanceTableProps> = (props) => {
  const {
    className,
    tableHeader,
    paginatedData,
    pageInt,
    pageSettings,
    isLoading,
    onSort,
    ...rest
  } = props

  return (
    <Table {...rest} className={style.table}>
      <TableHead
        tableHead={tableHeader}
        sortParams={{
          sortBy: pageSettings.sortBy,
          sortOrder: pageSettings.sortOrder,
        }}
        setSortParams={onSort}
      />
      <tbody>
        {!isLoading ? (
          paginatedData?.[pageInt]?.map(
            ({ symbol, amount, total, logoURI }) => (
              <tr key={symbol}>
                <td>
                  <div className={classNames('h5', style.colWithImg)}>
                    <img
                      src={logoURI}
                      alt={symbol}
                      width={24}
                      height={24}
                      onError={handleImgError}
                    />
                    {symbol}
                  </div>
                </td>
                <td>{amount.toLocaleString()}</td>
                <td>{total.toLocaleString()}</td>
              </tr>
            )
          )
        ) : (
          <TokenBalanceSkeleton />
        )}
      </tbody>
    </Table>
  )
}

export default TokenBalanceTable
