import RouteURL from '~routes'
import buildUrl from '~utils/buildUrl'

export const NAV_ITEMS = [
  {
    text: 'DeFi',
    link: RouteURL.DEFI,
    iconName: 'icon-nav-defi',
    subItems: [
      {
        text: 'Total Balance',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.TOTAL_BALANCE]),
      },
      {
        text: 'LP Tokens',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.LP_TOKENS]),
      },
      {
        text: 'Farms',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.FARMS]),
      },
      {
        text: 'Lending',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.LENDING]),
      },
      {
        text: 'Borrowing',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.BORROWING]),
      },
      {
        text: 'Staking',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.STAKING]),
      },
      {
        text: 'Vault',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.VAULT]),
      },
      {
        text: 'Swap',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.SWAP]),
      },
      {
        text: 'Spot Orders',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.SPOT_ORDERS]),
      },
      {
        text: 'Perpetual Orders',
        link: buildUrl([RouteURL.DEFI, RouteURL.Defi.PERPETUAL_ORDERS]),
      },
    ],
  },
  {
    text: 'NFTs',
    link: RouteURL.NFTS,
    iconName: 'icon-nav-nfts',
  },
  {
    text: 'Summary',
    link: RouteURL.SUMMARY,
    iconName: 'icon-nav-summary',
    subItems: [
      {
        text: 'Wallet Evaluation',
        link: buildUrl([RouteURL.SUMMARY, RouteURL.Summary.WALLET_EVALUATION]),
      },
      {
        text: 'Currencies held',
        link: buildUrl([RouteURL.SUMMARY, RouteURL.Summary.CURRENCIES_HELD]),
      },
      {
        text: 'Hot NFTs Treemap',
        link: buildUrl([RouteURL.SUMMARY, RouteURL.Summary.HOT_NFTS_TREEMAP]),
      },
      {
        text: 'Hot NFTs',
        link: buildUrl([RouteURL.SUMMARY, RouteURL.Summary.HOT_NFTS]),
      },
      {
        text: 'HFTs Held Treemap',
        link: buildUrl([RouteURL.SUMMARY, RouteURL.Summary.NFTS_HELD_TREEMAP]),
      },
    ],
  },
]
