import React from 'react'
import classNames from 'classnames'
import { Placeholder } from 'react-bootstrap'

import { handleImgError } from '~utils/handleImgError'
import Icon from '~components/Icon'

import * as style from './TokenCard.module.scss'

interface TokenCardProps {
  title?: string
  imgSrc?: string
  total?: string
  isLoading?: boolean
  onClick?: () => void
  className?: string
}

const TokenCard: React.FC<TokenCardProps> = (props) => {
  const { title, imgSrc, isLoading, total, onClick, className, ...rest } = props

  return (
    <button
      type="button"
      onClick={onClick}
      {...rest}
      className={classNames(style.tokenCard, 'placeholder-glow', className)}
    >
      <span className={style.tokenInfo}>
        <span className={style.tokenInfoImg}>
          {!isLoading ? (
            <img src={imgSrc} alt={`${title} logo`} onError={handleImgError} />
          ) : (
            <Placeholder style={{ width: '100%', height: '100%' }} />
          )}
        </span>
        {!isLoading ? (
          <>
            <h3>{title}</h3>
            <span className={classNames('h5', style.tokenInfoTotal)}>
              {total}
            </span>
          </>
        ) : (
          <>
            <Placeholder as="h3" style={{ width: '60px', marginTop: '2px' }} />
            <Placeholder
              style={{ width: '110px', height: '18px', marginTop: '4px' }}
            />
          </>
        )}
      </span>
      <Icon name="icon-arrow-small-right" size={24} />
    </button>
  )
}

export default TokenCard
