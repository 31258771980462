import { Ordering } from '~utils/enums'
import * as yup from 'yup'

export const SECTION_HEADING_TEXT = 'Collections'

export const searchParamsSchema = yup.object({
  pageSize: yup.mixed().oneOf(['10', '20', '50', '100']),
  sortBy: yup
    .mixed()
    .oneOf(['listings', 'buys', 'buys_volume', 'sales', 'sales_volume']),
  sortOrder: yup.mixed().oneOf(Object.values(Ordering)),
  periodFilter: yup.mixed().oneOf(['24h', '1w']),
  page: yup
    .string()
    .matches(/^\d+$/)
    .test((value: any) => (value ? parseInt(value, 10) >= 1 : false)),
})

interface InitialSettings {
  [index: string]: string
}

export const INITIAL_SETTINGS: InitialSettings = {
  pageSize: '10',
  page: '1',
  sortBy: 'sales_volume',
  periodFilter: '1w',
  sortOrder: Ordering.DESC,
}

export const PERIOD_FILTER_TABS = [
  { key: '24h', text: '24H' },
  { key: '1w', text: '1W', isActive: true },
]

export const PAGE_SIZE_FILTER_TABS = [
  { key: '10', text: '10', isActive: true },
  { key: '20', text: '20' },
  { key: '50', text: '50' },
  { key: '100', text: '100' },
]

export const TABLE_HEADER = [
  { text: 'No' },
  { text: 'Collection' },
  { text: 'Symbol' },
  { sortKey: 'listings', text: 'Listing' },
  { sortKey: 'buys', text: 'Buys' },
  { sortKey: 'buys_volume', text: 'Buys volume' },
  { sortKey: 'sales', text: 'Sales' },
  { sortKey: 'sales_volume', text: 'Sales volume' },
]

export const NFTS_SKELETON_ROW_COUNT = 10

export const IMGIX_PARAMS = {
  w: 48,
  ar: '1:1',
  fit: 'crop',
  auto: 'compress,format',
}

export const IMG_SIZES = '48px'

export const DEVICE_PIXEL_RATIOS = [1, 1.5, 2, 2.5, 3, 4]
