import React from 'react'
import { Dropdown } from 'react-bootstrap'

import updateActiveItem from '~utils/updateActiveItem'

export interface DropdownItem {
  key: string | number
  text: string
  isActive?: boolean
}

interface DropdownFilterProps {
  className?: string
  dropdownItems: DropdownItem[]
  setDropdownItems?: Function
  activeItemKey?: string | number
  setActiveItemKey?: Function
  label?: string
}

// TODO: remove setTabs when all pages are done via URL parameters
const DropdownFilter: React.FC<DropdownFilterProps> = (props) => {
  const {
    className,
    dropdownItems,
    setDropdownItems,
    label,
    activeItemKey,
    setActiveItemKey,
    ...rest
  } = props

  const activeItem = dropdownItems.find(({ key, isActive }) =>
    activeItemKey ? key.toString() === activeItemKey.toString() : isActive
  )

  const activeItemText = activeItem?.text || dropdownItems[0].text

  const otherItems = dropdownItems.filter(({ key, isActive }) =>
    activeItemKey ? key.toString() !== activeItemKey.toString() : !isActive
  )

  const handleSelect = (eventKey: any) => {
    setActiveItemKey?.(eventKey)
    setDropdownItems?.((prevState: DropdownItem[]) =>
      updateActiveItem(prevState, eventKey)
    )
  }

  return (
    <div className="dropdown-wrapper">
      {!!label && <span>{label}</span>}
      <Dropdown {...rest} onSelect={handleSelect}>
        <Dropdown.Toggle id="dropdown-per-page" variant="dropdown">
          {activeItemText}
        </Dropdown.Toggle>
        <Dropdown.Menu
          variant="dark"
          popperConfig={{
            modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
          }}
        >
          {otherItems.map(({ key, text }) => (
            <Dropdown.Item key={key} eventKey={key} as="button" type="button">
              {text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default DropdownFilter
