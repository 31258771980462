import { sum } from 'd3'

import { HotNft } from '~api/types'
import { TreemapDataItem } from '~components/TreemapStylized/types'
import { groupDataByQuantiles } from '~components/TreemapStylized/utils'
import { TabItem } from '~components/TabsFilter'
import sortAndSlice from '~utils/sortAndSlice'

export const processDataByFilter = (
  rawData: HotNft[] | undefined,
  isMobile: boolean,
  activeFilter: TabItem
) => {
  if (!rawData)
    return {
      collectionsCount: undefined,
      nftCount: undefined,
      groupedData: undefined,
    }

  const { filedName, countFieldName, valueFiledName } = activeFilter

  /* Lead text params start */
  const collectionsCount = rawData.filter(
    (item) => parseFloat(item[filedName]) > 0
  ).length
  const nftCount = sum(rawData, (item) => parseFloat(item[countFieldName]))
  const totalValue = sum(rawData, (item) => parseFloat(item[valueFiledName]))
  /* Lead text params end */

  const preparedData = rawData
    .map((nft) => ({
      name: nft.nft.collection,
      value: parseFloat(nft[filedName]),
    }))
    .filter(({ value }) => value > 0)

  const groupedData: TreemapDataItem[] = groupDataByQuantiles(
    isMobile ? sortAndSlice(preparedData) : preparedData,
    4
  )

  return { collectionsCount, nftCount, totalValue, groupedData }
}
