import React, { useState } from 'react'
import classNames from 'classnames'
import ReactPaginate from 'react-paginate'
import { isNil } from 'lodash'

import Icon from '~components/Icon'

import * as style from './Paginator.module.scss'

interface PaginatorProps {
  className?: string
  pageCount: number
  onPageChange?: Function
  selectedPage?: number
  setSelectedPage?: Function
}

const Paginator: React.FC<PaginatorProps> = (props) => {
  const {
    className,
    pageCount,
    onPageChange: onPageChangeProp,
    selectedPage: selectedPageProp,
    setSelectedPage: setSelectedPageProp,
    ...rest
  } = props

  const [selectedPageLocal, setSelectedPageLocal] = useState(0)

  const selectedPage = isNil(selectedPageProp)
    ? selectedPageLocal
    : selectedPageProp

  const setSelectedPage = isNil(setSelectedPageProp)
    ? setSelectedPageLocal
    : setSelectedPageProp

  const onPageChange = (page: any) => {
    const { selected } = page

    setSelectedPage(selected)

    if (onPageChangeProp) onPageChangeProp(page)
  }

  const isFirstPage = selectedPage === 0
  const isLastPage = selectedPage === pageCount - 1

  const handleGoToStart = () => setSelectedPage(0)
  const handleGoToEnd = () => setSelectedPage(pageCount - 1)

  return (
    <div
      {...rest}
      className={classNames(style.paginator, style.spacer, className)}
    >
      <button
        aria-label="First page"
        type="button"
        disabled={isFirstPage}
        className={classNames(style.pageLink, style.toStartLink, {
          [style.disabledLink]: isFirstPage,
        })}
        onClick={handleGoToStart}
      >
        <Icon name="icon-double-chevron-right" size={16} />
      </button>
      <ReactPaginate
        forcePage={selectedPage}
        breakLabel="..."
        nextLabel={<Icon name="icon-chevron-right" size={16} />}
        onPageChange={onPageChange}
        pageRangeDisplayed={4}
        marginPagesDisplayed={0}
        pageCount={pageCount}
        previousLabel={<Icon name="icon-chevron-right" size={16} />}
        renderOnZeroPageCount={() => null}
        className={style.paginator}
        pageLinkClassName={style.pageLink}
        activeLinkClassName={style.active}
        breakClassName={style.pageBreak}
        breakLinkClassName={style.pageLink}
        previousLinkClassName={classNames(style.pageLink, style.previousLink)}
        nextLinkClassName={style.pageLink}
        disabledLinkClassName={style.disabledLink}
      />
      <button
        aria-label="Last page"
        type="button"
        disabled={isLastPage}
        className={classNames(style.pageLink, style.toEndLink, {
          [style.disabledLink]: isLastPage,
        })}
        onClick={handleGoToEnd}
      >
        <Icon name="icon-double-chevron-right" size={16} />
      </button>
    </div>
  )
}

export default Paginator
