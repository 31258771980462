import { bin as d3bin, format as d3format } from 'd3'
import { TreemapDataItem } from '~components/TreemapStylized/types'

const DEFAULT_BINS = [1000, 10_000, 100_000, 1_000_000, 10_000_000]
const BIN_AREA_RATIOS = [1, 2, 4, 8, 16]
const NUMBERS_FORMAT = '.3~s'

const binItems = (data: TreemapDataItem[], bins = DEFAULT_BINS) => {
  const bin = d3bin()
    // @ts-ignore
    .value((item: TreemapDataItem) => item.value)
    .thresholds(bins)

  // @ts-ignore
  return bin(data)
}

export const groupByPrice = (
  data: TreemapDataItem[] | undefined,
  bins = DEFAULT_BINS,
  binAreaRatios = BIN_AREA_RATIOS
) => {
  if (!data) return undefined

  const binnedData = binItems(data, bins).slice(1)

  const dataWithParents = binnedData.flatMap((bin, binIndex) =>
    // @ts-ignore
    bin.map((item) => ({ ...item, parent: `bin-${binIndex}`, bin: binIndex }))
  )

  const formatter = d3format(NUMBERS_FORMAT)

  const dataWithRootLevel = [
    ...dataWithParents,
    ...bins.map((bin, binIndex) => ({
      name: `${formatter(bins[binIndex])}+`,
      id: `bin-${binIndex}`,
      value: binAreaRatios[binIndex],
    })),
  ]

  return dataWithRootLevel
}

function labelFormatterL2() {
  // @ts-ignore
  const { point } = this

  const formatter = d3format(NUMBERS_FORMAT)

  // @ts-ignore
  if (point.parent === point.series.rootNode) {
    // @ts-ignore
    return `${point.name} (${formatter(point.value)})`
  }

  return undefined
}

function tooltipFormatter() {
  // @ts-ignore
  const { point } = this

  const formatter = d3format(NUMBERS_FORMAT)

  // @ts-ignore
  if (point.parent === point.series.rootNode) {
    // @ts-ignore
    return `<b>${point.name}:</b> ${formatter(point.value)}`
  }

  return false
}

export const EXTRA_CHART_OPTIONS = {
  labelFormatterL2,
  tooltipFormatter,
  chartName: 'Currencies Held',
}
